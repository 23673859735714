import React from "react";
import moment from "moment";
import { IMAGE_URL } from "../../config";
import placeholder from '../../../src/components/assets/img/placeholder-img.png';

const Pdf_report = ({ extract_image, exportData, uniqueJobTypes, productUsed, defaultAllPdf }) => {

    return (
        <>

            {/* Report First Page */}
            <div class="main-table-sec font-family-arial">
                <table cellSpacing={0} cellPadding={0} style={{ width: '210mm' }}>
                    <tbody>
                        <tr>
                            <td colSpan={2} style={{
                                fontSize: '14px', fontFamily: 'Arial, Helvetica, sans-serif !important', padding: '14px', textAlign: 'center'
                            }}>
                                <p style={{
                                    fontSize: '18px', fontFamily: 'Arial, Helvetica, sans-serif !important', padding: '40px 0px', textAlign: 'center', fontWeight: 'bold'
                                }}>{exportData?.job_id?.JobRefNo.toString().length == 1
                                    ? '000' + exportData?.job_id?.JobRefNo
                                    : '' || exportData?.job_id?.JobRefNo.toString().length == 2
                                        ? '00' + exportData?.job_id?.JobRefNo
                                        : '' || exportData?.job_id?.JobRefNo.toString().length == 3
                                            ? '0' + exportData?.job_id?.JobRefNo
                                            : '' || exportData?.job_id?.JobRefNo.toString().length == 4
                                                ? exportData?.job_id?.JobRefNo
                                                : ''} Report </p>
                                <p style={{
                                    fontSize: '14px', fontFamily: 'Arial, Helvetica, sans-serif !important', padding: '14px', textAlign: 'center', fontWeight: 'bold'
                                }}>{exportData?.job_id?.addressLine1}</p>
                                <p>JOB SHEET <span class="pdf_pl_10">{exportData?.job_id?.JobRefNo}</span> </p>
                                <p>{moment().format("dddd, MMMM Do YYYY")}</p>
                                <p>Prepared For {exportData?.job_id?.clientId?.clientName}</p>
                                <p style={{
                                    fontSize: '14px', fontFamily: 'Arial, Helvetica, sans-serif !important', padding: '14px', textAlign: 'center', fontWeight: 'bold'
                                }}>{productUsed?.length > 0 ? productUsed?.length : defaultAllPdf?.length} Issues Identified</p>
                                <p>{localStorage.getItem("email")}</p>
                            </td>
                        </tr>
                        <div className="page-break"></div>
                        {
                            productUsed?.length > 0 ?
                                productUsed?.map((item, index) => {
                                    const skip = 5
                                    const total = defaultAllPdf?.length
                                    const arr = total / skip

                                    return (
                                        <>
                                            <tr key={item?._id} >
                                                <td style={{ width: '1200px' }}>
                                                    {item?.chat_id?.Image != undefined ?
                                                        <img src={`${IMAGE_URL}${item?.chat_id?.Image}`} alt="No Image Found" />
                                                        :
                                                        <img src={placeholder} alt="No Image Found" />
                                                    }

                                                </td>
                                                <td style={{ fontSize: '14px', fontFamily: 'Arial, Helvetica, sans-serif !important', padding: '14px' }}>
                                                    <h3 style={{ fontSize: '18px', fontFamily: 'Arial, Helvetica, sans-serif !important' }} >{item?.chat_id?.section_id?.jobType != "Surveying" ? "Evidence" + " " + `${index + 1}` : "Issue"}  </h3>
                                                    <p>{item?.chat_id?.section_id?.jobType}</p>
                                                    <p>Orchard Number : {item?.chat_id?.section_id?.chard_number}</p>
                                                    <p>{item?.chat_id?.message}</p>
                                                </td>
                                            </tr>
                                            {Array.from({ length: arr }, (_, i) => (i + 1) * skip).includes(index) &&
                                                <div className="page-break"></div>
                                            }
                                        </>
                                    )
                                })

                                :


                                defaultAllPdf?.map((item, index) => {
                                    const skip = 5
                                    const total = defaultAllPdf?.length
                                    const arr = total / skip
                                    return (
                                        <>
                                            <tr key={item?._id} >
                                                <td>
                                                    {item?.chat_id?.Image != undefined ?
                                                        <img src={`${IMAGE_URL}${item?.chat_id?.Image}`} alt="No Image Found" />
                                                        :
                                                        <img src={placeholder} alt="No Image Found" />
                                                    }

                                                </td>
                                                <td style={{ width: '2000px', fontSize: '14px', fontFamily: 'Arial, Helvetica, sans-serif !important', padding: '14px' }}>
                                                    <h3 style={{ fontSize: '18px', fontFamily: 'Arial, Helvetica, sans-serif !important', fontWeight: 'bold' }}>{item?.chat_id?.section_id?.jobType != "Surveying" ? "Evidence" + " " + `${index + 1}` : "Issue"}  </h3>
                                                    <p >{item?.chat_id?.section_id?.jobType}</p>
                                                    <p >Orchard Number : {item?.chat_id?.section_id?.chard_number}</p>
                                                    <p className="word-elips">{item?.chat_id?.message}</p>
                                                </td>
                                            </tr>
                                            {Array.from({ length: arr }, (_, i) => (i + 1) * skip).includes(index) &&
                                                <div className="page-break"></div>
                                            }
                                        </>
                                    )
                                })
                        }


                    </tbody>
                </table>

            </div>
            {/* <page size="A4">
                
            </page>
            <div class="main-table-sec1 font-family-arial">
                <table cellSpacing={0} cellPadding={0} style={{ width: '100%' }}>
                    <tbody>
                        <tr>
                            <td colSpan={2} style={{
                                fontSize: '14px', fontFamily: 'Arial, Helvetica, sans-serif !important', padding: '14px', textAlign: 'center'
                            }}>
                                <p style={{
                                    fontSize: '18px', fontFamily: 'Arial, Helvetica, sans-serif !important', padding: '40px 0px', textAlign: 'center', fontWeight: 'bold'
                                }}>{exportData?.job_id?.JobRefNo.toString().length == 1
                                    ? '000' + exportData?.job_id?.JobRefNo
                                    : '' || exportData?.job_id?.JobRefNo.toString().length == 2
                                        ? '00' + exportData?.job_id?.JobRefNo
                                        : '' || exportData?.job_id?.JobRefNo.toString().length == 3
                                            ? '0' + exportData?.job_id?.JobRefNo
                                            : '' || exportData?.job_id?.JobRefNo.toString().length == 4
                                                ? exportData?.job_id?.JobRefNo
                                                : ''} Report </p>
                                <p style={{
                                    fontSize: '14px', fontFamily: 'Arial, Helvetica, sans-serif !important', padding: '14px', textAlign: 'center', fontWeight: 'bold'
                                }}>{exportData?.job_id?.addressLine1}</p>
                                <p>JOB SHEET <span class="pdf_pl_10">{exportData?.job_id?.JobRefNo}</span> </p>
                                <p>{moment().format("dddd, MMMM Do YYYY")}</p>
                                <p>Prepared For {exportData?.job_id?.clientId?.clientName}</p>
                                <p style={{
                                    fontSize: '14px', fontFamily: 'Arial, Helvetica, sans-serif !important', padding: '14px', textAlign: 'center', fontWeight: 'bold'
                                }}>{productUsed?.length > 0 ? productUsed?.length : defaultAllPdf?.length} Issues Identified</p>
                                <p>{localStorage.getItem("email")}</p>
                            </td>
                        </tr>
                        <h3 className="page-break" style={{
                                                            width: '14px'
                                                        }}>Page 2</h3>
                        {
                            productUsed?.length > 0 ?
                                productUsed?.map((item, i) => {
                                    return (
                                        <>
                                            <tr key={item?._id} >
                                                <td>
                                                    {item?.chat_id?.Image != undefined ?
                                                        <img src={`${IMAGE_URL}${item?.chat_id?.Image}`} alt="No Image Found" style={{
                                                            width: '14px'}} />
                                                        :
                                                        <img src={placeholder} alt="No Image Found" style={{
                                                            width: '14px'
                                                        }} />
                                                    }

                                                </td>
                                                <td style={{ fontSize: '14px', fontFamily: 'Arial, Helvetica, sans-serif !important', padding: '14px' }}>
                                                    <h3 style={{ fontSize: '14px', fontFamily: 'Arial, Helvetica, sans-serif !important', padding: '14px' }} >{item?.chat_id?.section_id?.jobType != "Surveying" ? "Evidence" + " " + `${i + 1}` : "Issue"}  </h3>
                                                    <p>{item?.chat_id?.section_id?.jobType}</p>
                                                    <p>Orchard Number : {item?.chat_id?.section_id?.chard_number}</p>
                                                    <p>{item?.chat_id?.message}</p>
                                                </td>
                                            </tr>
                                        </>
                                    )
                                })

                                :


                                defaultAllPdf?.map((item, i) => {
                                    return (
                                        <>
                                            <tr key={item?._id} >
                                                <td>
                                                    {item?.chat_id?.Image != undefined ?
                                                        <img src={`${IMAGE_URL}${item?.chat_id?.Image}`} alt="No Image Found" style={{
                                                            width: '14px'
                                                        }} />
                                                        :
                                                        <img src={placeholder} alt="No Image Found" style={{
                                                            width: '14px'
                                                        }} />
                                                    }

                                                </td>
                                                <td style={{ fontSize: '14px', fontFamily: 'Arial, Helvetica, sans-serif !important', padding: '14px' }}>
                                                    <h3 style={{ fontSize: '14px', fontFamily: 'Arial, Helvetica, sans-serif !important', fontWeight: 'bold' }}>{item?.chat_id?.section_id?.jobType != "Surveying" ? "Evidence" + " " + `${i + 1}` : "Issue"}  </h3>
                                                    <p >{item?.chat_id?.section_id?.jobType}</p>
                                                    <p >Orchard Number : {item?.chat_id?.section_id?.chard_number}</p>
                                                    <p>{item?.chat_id?.message}</p>
                                                </td>
                                            </tr>
                                        </>
                                    )
                                })
                        }


                    </tbody>
                </table>

            </div> */}

            {/* Report Issues Second page */}
            {/* <div class="pdf_bg_color pdf_margin_100">    
                <table class="pdf_width100 pdf_height100 pdf_border pdf_margin_auto pdf_max_width pdf_border_spacing">
                    <tbody>
                        <tr>
                            <td class="pdf_height"></td>
                        </tr>
                        <tr>
                            <td class="pdf_text_center">
                                <table class="pdf_w_600 pdf_height100 pdf_border pdf_text_center pdf_border_spacing">
                                    <tbody>
                                        <tr>
                                            <td class="pdf_text_center">
                                                <table class="pdf_w_600 pdf_height100 pdf_border pdf_text_center pdf_border_spacing">                
                                                    <tbody class="pdf_bg_color_white">   
                                                        <tr class="pdf_bg_color_white">
                                                            <td class="pdf_p_70">
                                                                <table class="pdf_width100 pdf_height100 pdf_border">
                                                                    <tbody>
                                                                        <tr class="pdf_text_left pdf_bg_color_white">
                                                                            <td class="pdf_pb_20 pdf_width100">
                                                                                <table class="pdf_width100 pdf_height100 pdf_border pdf_border_spacing">
                                                                                    <tbody class="pdf_height100">
                                                                                        <tr class="pdf_text_left pdf_bg_color_white">
                                                                                            <td class="pdf_w_40 pdf_pr_20 pdf_border_bottom">                                                   
                                                                                                <img src="images/1.jpg" alt="" class="pdf_width100 pdf_height100 pdf_block"/>                                           
                                                                                            </td>  
                                                                                            <td class="pdf_w_60 pdf_padding_0 pdf_border_bottom">                                                   
                                                                                                <h3 class="pdf_dark_black pdf_margin_0 pdf_fz_16">Issue 1</h3>   
                                                                                                <p class="pdf_fz_14 pdf_margin_0">Plywood panel screwed to ceiling, top of stairs flat 5 + 6.Size 610 * 430mm</p>                                        
                                                                                            </td>    
                                                                                        </tr>
                                                                                    </tbody>
                                                                                </table>
                                                                            </td>                                                                                                  
                                                                        </tr> 
                                                                        <tr class="pdf_text_left pdf_bg_color_white">
                                                                            <td class="pdf_pb_20 pdf_width100">
                                                                                <table class="pdf_width100 pdf_height100 pdf_border pdf_border_spacing">
                                                                                    <tbody class="pdf_height100">
                                                                                        <tr class="pdf_text_left pdf_bg_color_white">
                                                                                            <td class="pdf_w_40 pdf_pr_20 pdf_border_bottom">                                                   
                                                                                                <img src="images/1.jpg" alt="" class="pdf_width100 pdf_height100 pdf_block"/>                                           
                                                                                            </td>  
                                                                                            <td class="pdf_w_60 pdf_padding_0 pdf_border_bottom">                                                   
                                                                                                <h3 class="pdf_dark_black pdf_margin_0 pdf_fz_16">Issue 2</h3>   
                                                                                                <p class="pdf_fz_14 pdf_margin_0">Cabel penetrations travelling in to communal roof void. Near flats 5 and 6</p>                                        
                                                                                            </td>    
                                                                                        </tr>
                                                                                    </tbody>
                                                                                </table>
                                                                            </td>                                                                                                  
                                                                        </tr> 
                                                                        <tr class="pdf_text_left pdf_bg_color_white">
                                                                            <td class="pdf_pb_20 pdf_width100">
                                                                                <table class="pdf_width100 pdf_height100 pdf_border pdf_border_spacing">
                                                                                    <tbody class="pdf_height100">
                                                                                        <tr class="pdf_text_left pdf_bg_color_white">
                                                                                            <td class="pdf_w_40 pdf_pr_20 pdf_border_bottom">                                                   
                                                                                                <img src="images/1.jpg" alt="" class="pdf_width100 pdf_height100 pdf_block"/>                                           
                                                                                            </td>  
                                                                                            <td class="pdf_w_60 pdf_padding_0 pdf_border_bottom">                                                   
                                                                                                <h3 class="pdf_dark_black pdf_margin_0 pdf_fz_16">Issue 3</h3>   
                                                                                                <p class="pdf_fz_14 pdf_margin_0">Cabel penetrations travelling in to communal roof void. Near flats 5 and 6</p>                                        
                                                                                            </td>    
                                                                                        </tr>
                                                                                    </tbody>
                                                                                </table>
                                                                            </td>                                                                                                  
                                                                        </tr> 
                                                                        <tr class="pdf_text_left pdf_bg_color_white">
                                                                            <td class="pdf_pb_20 pdf_width100">
                                                                                <table class="pdf_width100 pdf_height100 pdf_border pdf_border_spacing">
                                                                                    <tbody class="pdf_height100">
                                                                                        <tr class="pdf_text_left pdf_bg_color_white">
                                                                                            <td class="pdf_w_40 pdf_pr_20 pdf_border_bottom">                                                   
                                                                                                <img src="images/1.jpg" alt="" class="pdf_width100 pdf_height100 pdf_block"/>                                           
                                                                                            </td>  
                                                                                            <td class="pdf_w_60 pdf_padding_0 pdf_border_bottom">                                                   
                                                                                                <h3 class="pdf_dark_black pdf_margin_0 pdf_fz_16">Issue 4</h3>   
                                                                                                <p class="pdf_fz_14 pdf_margin_0">Cabel penetrations running through wall in to flat - Above flat 6 door</p>                                        
                                                                                            </td>    
                                                                                        </tr>
                                                                                    </tbody>
                                                                                </table>
                                                                            </td>                                                                                                  
                                                                        </tr> 

                                                                    </tbody>
                                                                </table>
                                                            </td>
                                                        </tr>                                      
                                                    </tbody>
                                                </table>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                        </tr>  
                        <tr>
                            <td class="pdf_height"></td>
                        </tr>
                    </tbody>
                </table>
            </div> */}
        </>
    )
}

export default Pdf_report
