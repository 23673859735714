import React, { useEffect } from 'react';
import { getAllJobtype } from '../../Redux/Action/SuperAdminAction';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { PDFExport } from '@progress/kendo-react-pdf';

const Report_type_select_section = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const client = useSelector((state) => state?.adminList?.GetAllJobTypes?.clientname?.clientId?.clientName);
  const uniqueJobTypes = useSelector((state) => state?.adminList?.GetAllJobTypes?.data);

  const pdfExportComponent = React.useRef(null);

  const exportPDFWithComponent = () => {
    if (pdfExportComponent.current) {
      pdfExportComponent.current.save();
    }
  };

  useEffect(() => {
    localStorage.setItem('report_type_select_sec_1', props.match.params.id);
    localStorage.setItem(
      'report_type_select_sec_2',
      props.match.params.jobtype == 'Electrical-Alarm'
        ? 'Electrical/Alarm'
        : props.match.params.jobtype
    );
    dispatch(getAllJobtype(props.match.params.id, props.match.params.jobtype));
  }, [props.match.params.id, props.match.params.jobtype]);

  return (
    <>
      {/* <!-- Page Wrapper --> */}
      <div class='page-wrapper'>
        {/* <!-- Page Content --> */}
        <div class='content container-fluid'>
          {/* <!-- Page Header --> */}

          <div class='page-header'>
            <div class='row align-items-center d-flex'>
              <div class='col-md-6'>
                <h3 class='page-title'>{client}</h3>
              </div>
              <div class='col-md-6'>
                <div class='form-group mb-0'>
                  <select class='form-control' id=''>
                    <option>0001</option>
                    <option>0002</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div className='row mb-2'>
            <div className='col-md-12'>
              <div
                onClick={() =>
                  history.push(
                    `/report_type/${localStorage.getItem('report_type')}`
                  )
                }
              >
                <button class='btn add-btn pull-left'>Back</button>
              </div>
            </div>
          </div>

          {/* <!-- /Page Header --> */}
          <div class='row'>
            <div class='col-md-12 d-flex'>
              <div class=' card-table flex-fill'>
                <div class='card-body'>
                  <PDFExport
                    ref={pdfExportComponent}
                    paperSize='auto'
                    margin={40}
                    fileName={`Report for ${new Date().getFullYear()}`}
                    author='KendoReact Team'
                  >
                    <div class='table-responsive'>
                      <table class=' table table-stripped mb-0'>
                        <thead class='custom_color'>
                          <tr>
                            <th>Section</th>
                            <th colspan='2'>Type</th>
                          </tr>
                        </thead>
                        <tbody>
                          {uniqueJobTypes?.map((item, i) => {
                            return (
                              <>
                                <tr class='text-middle-custom'>
                                  <td
                                    style={{ cursor: 'pointer' }}
                                    onClick={() =>
                                      history.push(
                                        `/report_view_section/${item?._id}`
                                      )
                                    }
                                  >
                                    {item?.section_number}
                                  </td>
                                  <td
                                    style={{ cursor: 'pointer' }}
                                    onClick={() =>
                                      history.push(
                                        `/report_view_section/${item?._id}`
                                      )
                                    }
                                  >
                                    {item?.jobType}
                                  </td>
                                  <td
                                    style={{ cursor: 'pointer' }}
                                    onClick={() =>
                                      history.push(
                                        `/report_view_section/${item?._id}`
                                      )
                                    }
                                    class='arrow_end'
                                  >
                                    <i
                                      class='fa fa-angle-right'
                                      aria-hidden='true'
                                    ></i>
                                  </td>
                                </tr>
                              </>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                  </PDFExport>
                  <div class='row mt-4'>
                    <div class='col-md-12 d-flex justify-content-end'>
                      <button
                        class='blue-custom-btn'
                        onClick={exportPDFWithComponent}
                      >
                        Export / View as PDF
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- /Page Content --> */}
      </div>
      {/* <!-- /Page Wrapper --> */}
    </>
  );
};

export default Report_type_select_section;
