export const registerServiceWorker = () => {
  if ('serviceWorker' in navigator) {
    console.log(navigator, 'registerServiceWorker navigator');
    navigator.serviceWorker
      .register('firebase-messaging-sw.js')
      .then(function (registration) {
        // eslint-disable-next-line no-console
        console.log(registration.scope, '[SW]: SCOPE: ');
        return registration.scope;
      })
      .catch(function (err) {
        console.log(err, 'registerServiceWorker error');
      });
  }
};
