import React from 'react'
import { useFormik } from 'formik';
import * as Yup from "yup";
import { Button } from 'react-bootstrap'
function ChangePassword() {

  const formik = useFormik({
    initialValues: {
      new_password: '',
      confirm_password: ""
    },
    validationSchema: Yup.object({
      new_password: Yup.string()
        .required("NewPassword is Required").matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field "),
      confirm_password: Yup.string()
        .required("ConfirmPassword is Required").matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field ")
    }),
    onSubmit: values => {
    },
  });

  return (
    <div className="page-wrapper">
      <form className="content container-fluid" onSubmit={formik.handleSubmit}>
        <div className="page-header">
          <div className=" out-box">
            <h3 className="page-title second-page-title">Change your password</h3>
            <div className="form-group mt-4">
              <label className="col-form-label">New Password<span className="text-danger">*</span></label>
              <input className="form-control mb-3" name='new_password' type="password"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.new_password}
              />
              {formik.touched.new_password && formik.errors.new_password ? (
                <p style={{ color: "red" }}>{formik.errors.new_password}</p>
              ) : null}
              <label className="col-form-label ">Confirm New Password<span className="text-danger">*</span></label>
              <input className="form-control" name='confirm_password' type="password"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.confirm_password}
              />
              {formik.touched.confirm_password && formik.errors.confirm_password ? (
                <p style={{ color: "red" }}>{formik.errors.confirm_password}</p>
              ) : null}
              <Button className="mt-4 full-width-btn" variant="primary">Change Password</Button>{' '}
            </div>
          </div>
        </div>
      </form>
    </div>
  )
}

export default ChangePassword
