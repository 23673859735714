import React, { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import SortIcon from '@material-ui/icons/ArrowDownward';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import { Button, Modal } from 'react-bootstrap';
import { Link, useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import {
  get_product_listdata,
  deleteproduct,
} from '../../Redux/Action/SuperAdminAction';

function ProductList() {

  const dispatch = useDispatch();
  const history = useHistory();

  const [showDelete, setDeleteShow] = useState(false);
  const [del, setDel] = useState('');

  const Product = useSelector((state) => state.adminList.GETPRODUCT);

  const handleButtonDeleteClick = (id) => {
    dispatch(deleteproduct(id));
    setDeleteShow(false);
  };
  const handleButtonEditClick = (id) => {
    history.push(`/editProduct/${id}`);
  };

  const handledeleteShow = (ror_id) => {
    setDel(ror_id);
    setDeleteShow(true);
  };
  const handledeleteClose = () => {
    setDeleteShow(false);
  };

  const columns = [
    {
      id: 1,
      name: 'NAME',
      selector: (row) => <b>{row?.name}</b>,
      reorder: true,
    },
    {
      id: 2,
      name: 'LABEL',
      selector: (row) => row?.lable,
      reorder: true,
    },
    {
      id: 3,
      name: 'STATUS',
      selector: (row) => row?.status,
      reorder: true,
    },
    {
      id: 4,
      name: 'STOCK',
      selector: (row) => row?.stock,
      reorder: true,
    },
    {
      id: 5,
      name: 'PRICE',
      selector: (row) => row?.Price,
      reorder: true,
    },
    {
      id: 6,
      name: 'ACTIONS',
      cell: (row) => (
        <>
          <span onClick={() => handleButtonEditClick(row._id)}>
            <EditIcon /> Edit
          </span>
          {'     '}
          <span className='ml-3' onClick={() => handledeleteShow(row._id)}>
            <DeleteIcon /> Delete
          </span>
        </>
      ),
    },
  ];

  useEffect(() => { dispatch(get_product_listdata()) }, []);
  
  return (
    <div className='page-wrapper'>
      <div className='content container-fluid'>
        <div className='page-header'>
          <div className='row align-items-center'>
            <div className='col'>
              <h3 className='page-title'>Products</h3>
            </div>
            <div className='col-auto float-right ml-auto'>
              <Link to='/add_product' className='btn add-btn'>
                Create
              </Link>
              <Link
                to='/category_list'
                className='btn add-btn'
                style={{ marginRight: '10px' }}
              >
                Product Category
              </Link>
            </div>
          </div>
        </div>
        <div className='row'>
          <div className='col-md-12 d-flex'>
            <div className=' card-table flex-fill'>
              <div className='card-body'>
                <div className='table-responsive'>
                  <DataTable
                    columns={columns}
                    data={Product?.data}
                    defaultSortFieldId={1}
                    sortIcon={<SortIcon />}
                    pagination
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal show={showDelete} onHide={handledeleteClose} animation={false}>
        <Modal.Header closeButton className='b-none'></Modal.Header>
        <Modal.Body className='p-4 main_modal'>
          <div className='text_del text-center'>
            <h3>Are you sure?</h3>
          </div>
          <div className='d-flex pb-2 width_cus mt-4 justify-content-around'>
            <Button
              variant='secondary'
              className='btn btn-primary '
              onClick={handledeleteClose}
            >
              Cancel
            </Button>
            <Button
              variant='danger'
              onClick={() => handleButtonDeleteClick(del)}
            >
              Delete
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default ProductList;
