import React, { useState, useEffect } from 'react';
import DataTable from 'react-data-table-component';
import SortIcon from '@material-ui/icons/ArrowDownward';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import { Link, useLocation } from 'react-router-dom';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Modal } from 'react-bootstrap';
import moment from 'moment';
import {
  getAllJob,
  deleteJob,
  globalFilter,
  getClinetList,
} from '../../Redux/Action/SuperAdminAction';
import { toast } from '../ToastContainer/Toast';
import ReactPaginate from 'react-paginate';
import { DateRangePicker, Loader } from 'rsuite';
import 'rsuite/dist/rsuite.css';


function Job() {
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation()
  const params = new URLSearchParams(location.search);
  const pageCount = params.get('page')

  let GetJobData = useSelector((state) => state?.adminList?.GetJob);
  const GetJob_MetaData = useSelector((state) => state?.adminList?.GetJob_MetaData);
  const global_filter = useSelector((state) => state?.adminList?.globalFilter);
  const clientName = useSelector((state) => state.adminList.clientList);

  const [searchInput, setSearchInput] = useState('');
  const [page, setPage] = useState(pageCount || 1);
  const [searchData, setSearchData] = useState('');
  const [client_name, setClient_name] = useState();
  // const [userInteraction, setUserInteraction] = useState(false);
  const [load, setLoad] = useState(true);
  const [dateRange, setDateRangeValue] = useState([]);
  const [clicked, setclicked] = useState(false);
  const [clickedIndex, setClickedIndex] = useState('');
  const [showDelete, setDeleteShow] = useState(false);
  const [del, setDel] = useState('');
  // const [checked, setchecked] = useState(false);

  // LOGIC FOR UNIQUE JOB LIST
  // const key = '_id';

  // const arrayUniqueByKey = [
  //   ...new Map(
  //     GetJobData && GetJobData?.map((item) => [item[key], item])
  //   ).values(),
  // ];
  //END LOGIC

  // LOGIC FOR UNIQUE JOB COUNT
  // var arrkk = [];
  // var statusGetJobColor = [];


  // for (let i = 0; i < arrayUniqueByKey?.length; i++) {
  //   var enabledCount = []
  //     .concat(...GetJobData)
  //     .filter((item) => item._id == arrayUniqueByKey[i]._id).length;
  //   const result = GetJobData.filter(
  //     (item) => item._id == arrayUniqueByKey[i]._id
  //   );
  //   statusGetJobColor.push(result);

  //   arrkk.push({ count: enabledCount });
  // }


  //END LOGIC
  // for (let i = 0; i < arrayUniqueByKey?.length; i++) {
  //   arrayUniqueByKey[i]['count'] = arrkk[i].count;
  // }


  const handleButtonEditClick = (id) => {
    // dispatch(getdetailofjob(id));
    history.push(`/jobs/edit/${id}`);
    toast.info("Editing this will remove all previously placed Floor Plan Markers")
  };

  const handleButtonDeleteClick = (id) => {
    dispatch(deleteJob(id));
    dispatch(getAllJob(page, searchInput.trim(), { client_name, range: dateRange }));
    setDeleteShow(false);
    // setPage(1)
    // window.location.reload()
  };

  // const handleChange = () => {
  //   setchecked(!checked);
  // };

  const handledeleteShow = (ror_id) => {
    setDel(ror_id);
    setDeleteShow(true);
  };

  const handledeleteClose = () => {
    setDeleteShow(false);
  };

  // const ObjectRow = () => {
  //   return (
  //     <>
  //       <span className={'c-blue'} />
  //     </>
  //   );
  // };

  const handlePageClick = (event) => {
    // if (userInteraction) {
    const currentPage = event?.selected + 1
    params.set('page', currentPage);
    window.history.pushState({}, '', `${location.pathname}?${params.toString()}`)
    setPage(currentPage);
    dispatch(globalFilter({
      search: global_filter?.search || searchData,
      page: currentPage,
      client: global_filter?.client || client_name,
      range: global_filter?.range || dateRange
    }))
    // }
    // setUserInteraction(true)
  };

  // const dataSearch = {

  //   statusGetJobColor: statusGetJobColor
  //   // ?.filter((item) =>
  //   //   item[0]?.clientId?.clientName.toLowerCase().includes(searchInput.toLowerCase()) ||
  //   //   item[0]?.addressLine1?.toLowerCase().includes(searchInput.toLowerCase()) ||
  //   //   ('000' + item[0]?.JobRefNo.toString()).includes(searchInput) ||
  //   //   ('00' + item[0]?.JobRefNo.toString()).includes(searchInput) ||
  //   //   ('0' + item[0]?.JobRefNo.toString()).includes(searchInput)


  //   // ),
  // };


  const ObjectRow2 = (statusGetJobColor) => {
    return (
      <>
        <div>
          {statusGetJobColor?.contractorInfo?.map((item, index) => {
            return (
              <>
                {item?.jobstatus == 1 ? (
                  <div className='status-darkblue m-1'></div>
                ) : item?.jobstatus == 2 ? (
                  <div className='status-lightblue m-1'></div>
                ) : item?.jobstatus == 3 ? (
                  <div className='status-lightblue m-1'></div>
                ) : item?.jobstatus == 4 ? (
                  <div className='status-yellow m-1'></div>
                ) : item?.jobstatus == 5 ? (
                  <div className='status-green m-1'></div>
                ) : item?.jobstatus == 9 ? (
                  <div className='status-darkblue m-1'></div>
                ) : (
                  ''
                )}
              </>
            );
          })}
        </div>
      </>
    );
  };

  const toggleRef = (row) => {
    return (
      <>
        {row?.map((item, i) => {
          return (
            <>
              <div className='center_div'>
                <div>
                  <div className='jobtype-name-flame'>
                    {item?.contractorInfo?.jobType} by{' '}
                    {moment(item?.contractorInfo?.completedBy).format(
                      'D MMM YYYY'
                    )}
                    {item?.contractorInfo?.jobstatus == 1 ? (
                      <span className='avatar2 status-lightblue' />
                    ) : item?.contractorInfo?.jobstatus == 2 ? (
                      <span className='avatar2 status-darkblue' />
                    ) : item?.contractorInfo?.jobstatus == 3 ? (
                      <span className='avatar2 status-lightblue' />
                    ) : item?.contractorInfo?.jobstatus == 4 ? (
                      <span className='avatar2 status-yellow'></span>
                    ) : item?.contractorInfo?.jobstatus == 5 ? (
                      <span className='avatar2 status-green' />
                    ) : (
                      ''
                    )}
                  </div>
                </div>
              </div>
            </>
          );
        })}
      </>
    );
  };

  const setclickedHandle = (click, index) => {
    setclicked(click);
    setClickedIndex(index);
  };

  const handleEnterClick = (e) => {
    if (e.key == 'Enter') {
      setSearchInput(searchData)
      setPage(1)
      dispatch(globalFilter({
        search: global_filter?.search || searchData,
        page: 1,
        client: global_filter?.client || client_name,
        range: global_filter?.range || dateRange
      }))
    }
  }

  const handleClientChange = (data) => {
    if (data == '') {
      dispatch(globalFilter({}))
    }
    setSearchInput(searchData)
    setPage(1)
    setClient_name(data)
    dispatch(globalFilter({
      search: global_filter?.search || searchData,
      page: 1,
      client: data,
      range: global_filter?.range || dateRange
    }))
  }

  const handleDateChange = (dates) => {
    setDateRangeValue(dates);
    setPage(1)
    dispatch(globalFilter({
      search: global_filter?.search || searchData,
      page: 1,
      client: global_filter?.client || client_name,
      range: dates
    }))

  };

  const columns = [
    {
      id: 1,
      name: 'JOB REF NO',

      cell: (row) => (
        <>
          <div className='id-part-sec'>
            <div className='job-id-c'>
              <b>
                <u
                  onClick={() => history.push(`/jobs/jobsdetail/${row._id}`)}
                  style={{ cursor: 'pointer' }}
                >
                  {row.JobRefNo.toString().length == 1
                    ? '000' + row.JobRefNo
                    : '' || row.JobRefNo.toString().length == 2
                      ? '00' + row.JobRefNo
                      : '' || row.JobRefNo.toString().length == 3
                        ? '0' + row.JobRefNo
                        : '' || row.JobRefNo.toString().length == 4
                          ? row.JobRefNo
                          : ''}
                </u>
              </b>
            </div>
            <div className='show-hide-text'>
              {clicked && clickedIndex == row.JobRefNo
                ? toggleRef(row)
                : null}
              <div className='dot_css'>
                {clicked == false ? (
                  <span
                    onClick={() => setclickedHandle(!clicked, row.JobRefNo)}
                    className={'status-lightblue-static'}
                  ></span>
                ) : (
                  clickedIndex == row.JobRefNo && (
                    <span
                      class='job-view-btn-c'
                      onClick={() =>
                        setclickedHandle(!clicked, row.JobRefNo)
                      }
                    >
                      close
                    </span>
                  )
                )}
                {/* {clicked ? (
                  <span
                    onClick={() => setclicked(!clicked)}
                    className='c-lightblue'
                  ></span>
                ) : (
                  <></>
                )} */}
              </div>
            </div>
          </div>
        </>
      ),
      selector: (row) => row.JobRefNo.toString().length == 1
        ? '000' + row.JobRefNo
        : '' || row.JobRefNo.toString().length == 2
          ? '00' + row.JobRefNo
          : '' || row.JobRefNo.toString().length == 3
            ? '0' + row.JobRefNo
            : '' || row.JobRefNo.toString().length == 4
              ? row.JobRefNo
              : '',
      sortable: true,
      reorder: true,
    },
    {
      id: 2,
      name: 'CLIENT NAME',
      selector: (row) => row?.clientId?.clientName,
      sortable: true,
      reorder: true,
    },
    {
      id: 3,
      name: 'CREATED DATE',
      selector: (row) => moment(row?.createdAt).format('D MMM YYYY'),
      sortable: true,
      reorder: true,
    },
    {
      id: 4,
      name: 'LOCATION',
      selector: (row) => row?.addressLine1,
      sortable: true,
      reorder: true,
    },
    {
      id: 5,
      name: 'STATUS',
      cell: (row) => {
        var rows = [],
          rows2 = [];

        rows2.push(ObjectRow2(row));

        return (
          <>
            <div className='center_div'>{rows2}</div>
          </>
        );
      },
    },
    {
      id: 6,
      name: 'ACTIONS',
      cell: (row) => (
        <>
          <span
            className='cursor-pointer'
            onClick={() => {
              history.push(`/jobs/jobsdetail/${row?._id}`);
              // localStorage.setItem('jobEdit', row?._id);
              //window.location.reload(false)
            }}
          >
            <VisibilityIcon style={{ marginLeft: '6px' }} /> View
          </span>
          {'     '}
          <span
            className='cursor-pointer'
            onClick={() => handleButtonEditClick(row?._id)}
          >
            <EditIcon style={{ marginLeft: '5px' }} /> Edit
          </span>

          {(localStorage.getItem("roles") == "superadmin" || localStorage.getItem("email") == "anita@flamehold.co.uk" || localStorage.getItem("email") == "admin@flamehold.co.uk")
            &&
            <span
              className='cursor-pointer'
              onClick={() => handledeleteShow(row?.jobinfo?._id)}
            >
              <DeleteIcon style={{ marginLeft: '9px' }} /> Delete
            </span>

          }
        </>
      ),
    },
  ];

  useEffect(() => {
    dispatch(getClinetList())
  }, [])

  useEffect(() => {
    // console.log(global_filter, dateRange, "inside useEffect")
    // if (global_filter) {
    //   dispatch(getAllJob(global_filter?.page || page, global_filter?.search?.trim() || searchInput.trim(), { client_name: global_filter?.client, range: global_filter?.range }));
    // } else {
    dispatch(getAllJob((page || pageCount), searchInput.trim(), { client_name, range: dateRange })).then(() => setLoad(false));
    // }
    //   dispatch(searchJobData(searchInput));
  }, [page, pageCount, searchInput, global_filter, dateRange]);


  return (
    <div className='page-wrapper'>
      <div className='content container-fluid'>
        <div className='page-header border-blue-top1'>
          <div className='row align-items-center'>
            <div className='col'>
              <h3 className='page-title '>Jobs</h3>
            </div>
            <div onClick={() => {
              localStorage.setItem("editPreview", "create");
            }} className='col-auto float-right ml-auto'>
              <Link to='/jobs/create' className='btn add-btn'>
                {' '}
                Create
              </Link>
            </div>
          </div>
        </div>
        <div className='page-header bnone'>
          <div class='row align-items-center d-flex '>
            <div className='col-md-2'>
              <div className='search-input-in'>
                <input
                  type='text'
                  onChange={(e) => setSearchData(e.target.value)}
                  onKeyDown={(e) => handleEnterClick(e)}
                  class='form-control border-l-none'
                  placeholder='Keyword Search..'
                />
              </div>
            </div>
            <div className='col-md-2'>
              <select
                name='clientId'
                className='form-control select-c-in'
                onChange={(e) => handleClientChange(e.target.value)}
              >
                <option value='' >
                  Select Client
                </option>
                {clientName?.data?.map((clientList) => (
                  <>
                    <option value={clientList.clientName}>
                      {clientList?.clientName}
                    </option>
                  </>
                ))}
              </select>
            </div>
            <div className='col-md-2'>
              {/* <select className='select-c-in'>
                <option>Job Created Date </option>
                <option>Job Created Date </option>
                <option>Job Created Date </option>
              </select> */}
              <DateRangePicker
                placeholder="Select Date Range"
                value={dateRange}
                onChange={handleDateChange}
                cleanable={true}
              />
            </div>

            <div className='col-md-2'>
              <button onClick={() => window.location.reload()} className='btn add-btn reset-btn-outline'>
                {' '}
                Reset Filters
              </button>
            </div>
            <div className='col-md-4'>
              <div className="row">
                <div className="col-md-6">
                  <div className="indi-view">
                    <div className="indi-circle">
                      <div class="status-darkblue m-0"></div>
                    </div>
                    <div className="indi-circle-name font14">
                      Job Created
                    </div>
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="indi-view">
                    <div className="indi-circle">
                      <div class="status-yellow m-0"></div>
                    </div>
                    <div className="indi-circle-name font14">
                      Paused
                    </div>
                  </div>
                </div>

                <div className="col-md-6 mt-2">
                  <div className="indi-view">
                    <div className="indi-circle">
                      <div class="status-lightblue m-0"></div>
                    </div>
                    <div className="indi-circle-name font14">
                      Started by Contractor
                    </div>
                  </div>
                </div>

                <div className="col-md-6 mt-2">
                  <div className="indi-view">
                    <div className="indi-circle">
                      <div class="status-green m-0"></div>
                    </div>
                    <div className="indi-circle-name font14">
                      Completed
                    </div>
                  </div>
                </div>
                <div className="col-md-6 mt-2">
                  <div className="indi-view">
                    <div className="indi-circle">
                      <div class="status-darkblue m-0"></div>
                    </div>
                    <div className="indi-circle-name font14">
                      Job Restarted
                    </div>
                  </div>
                </div>

              </div>
            </div>

          </div>

        </div>
        <div className='row'>
          <div className='col-md-12 d-flex'>
            <div className=' card-table flex-fill'>
              <div className='card-body job-sec-table'>
                <div className='table-responsive'>
                  <DataTable
                    columns={columns}
                    data={load ? [] : GetJobData}
                    defaultSortFieldId={1}
                    sortIcon={<SortIcon />}
                    // noDataComponent={<>{loading ? <Loader /> : !GetJobData.length && 'No Data Found'}</>}
                    noDataComponent={<>{load ? <Loader /> : !GetJobData.length ? 'No Data Found' : []}</>}
                  // pagination
                  />

                  {GetJobData.length ? <ReactPaginate
                    nextLabel="Next >"
                    onPageChange={handlePageClick}
                    pageRangeDisplayed={3}
                    forcePage={page - 1}
                    pageCount={GetJob_MetaData?.total / 10}
                    previousLabel="< Previous"
                    renderOnZeroPageCount={null}
                    containerClassName="pagination"
                    pageClassName="page-item"
                    pageLinkClassName="page-link"
                    previousClassName="page-item"
                    previousLinkClassName="page-link"
                    nextClassName="page-item"
                    nextLinkClassName="page-link"
                    breakLabel="..."
                    breakClassName="page-item"
                    breakLinkClassName="page-link"
                    activeClassName="active"
                  /> : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal show={showDelete} onHide={handledeleteClose} animation={false}>
        <Modal.Header closeButton className='b-none'></Modal.Header>
        <Modal.Body className='p-4 main_modal'>
          <div className='text_del text-center'>
            <h3>Are you sure?</h3>
          </div>
          <div className='d-flex pb-2 width_cus mt-4 justify-content-around'>
            <Button
              variant='secondary'
              className='btn btn-primary '
              onClick={handledeleteClose}
            >
              Cancel
            </Button>

            <Button
              variant='danger'
              onClick={() => handleButtonDeleteClick(del)}
            >
              Delete
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default Job;
