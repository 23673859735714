import React, { useEffect } from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  LineElement,
  BarElement,
  PointElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { useDispatch, useSelector } from 'react-redux';
import DataTable from 'react-data-table-component';
import SortIcon from '@material-ui/icons/ArrowDownward';
import { HashLink } from 'react-router-hash-link';
import moment from 'moment';
import {
  getAllJob,
  getContractorList,
  getClinetList,
  DashBoardData,
  getDeviceToken,
} from '../../Redux/Action/SuperAdminAction';
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  LineElement,
  PointElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: 'top',
    },
    title: {
      display: true,
      text: '',
    },
  },
};

function DashBoard() {
  const dispatch = useDispatch();

  const GetJobData = useSelector((state) => state?.adminList?.GetJob);
  const overdudate = useSelector((state) => state?.adminList?.dashboarddata?.overdudate);
  const pendingsection = useSelector((state) => state?.adminList?.dashboarddata?.pendingsection);
  const timesheet = useSelector((state) => state?.adminList?.dashboarddata?.timesheet);
  const Due_in_under = useSelector((state) => state?.adminList?.dashboarddata?.Due_in_under);
  const totalsection = useSelector((state) => state?.adminList?.dashboarddata?.totalsection);

  const filter_by_year = useSelector((state) => state?.adminList?.dashboarddata?.filterdata_push);

  var uniquegraph = [...new Set(totalsection?.map((item) => item.completedBy))];
  var uniquegraph2 = [...new Set(filter_by_year?.map((item) => item.completedBy))];

  var uniquemontharr = [],
    lastDateGraphArr = [],
    completeSec = [],
    uniqueYearArr = [];

  for (let i = 0; i < uniquegraph?.length; i++) {
    const year_data = uniquegraph[i].split('-')[0];
    if (year_data != undefined) {
      uniqueYearArr.push(year_data);
    }
  }

  for (let i = 0; i < uniquegraph2?.length; i++) {
    const data = uniquegraph2[i].split('-')[1];

    if (data != undefined) {
      uniquemontharr.push(data);
    }
  }

  var completeUniqueMonth = [...new Set(uniquemontharr?.map((item) => item))];
  var completeUniqueYear = [...new Set(uniqueYearArr?.map((item) => item))];

  completeUniqueMonth?.sort();
  completeUniqueYear?.sort();

  for (let i = 0; i < completeUniqueMonth?.length; i++) {
    var uniquegraph = filter_by_year.filter(function (el) {
      return el?.completedBy.split('-')[1] == completeUniqueMonth[i];
    });
    var uniqueStatus = filter_by_year?.filter(function (el) {
      return (
        el?.completedBy.split('-')[1] == completeUniqueMonth[i] &&
        el?.jobstatus == '5'
      );
    });
    completeSec.push(uniqueStatus?.length);
    lastDateGraphArr.push(uniquegraph.length);
  }

  const key = '_id';

  const arrayUniqueByKey = [...new Map(GetJobData && GetJobData?.map((item) => [item[key], item])).values()];
  //END LOGIC

  // LOGIC FOR UNIQUE JOB COUNT
  var arrkk = [];
  var statusGetJobColor = [];

  for (let i = 0; i < arrayUniqueByKey?.length; i++) {
    var enabledCount = []
      .concat(...GetJobData)
      .filter((item) => item._id == arrayUniqueByKey[i]._id).length;
    const result = GetJobData.filter(
      (item) => item._id == arrayUniqueByKey[i]?._id
    );
    statusGetJobColor.push(result);
    arrkk.push({ count: enabledCount });
  }

  //END LOGIC
  for (let i = 0; i < arrayUniqueByKey?.length; i++) {
    arrayUniqueByKey[i]['count'] = arrkk[i]?.count;
  }

  
  useEffect(() => {
    dispatch(getAllJob());
    dispatch(getContractorList());
    dispatch(getDeviceToken());
    dispatch(getClinetList());
    dispatch(DashBoardData());
  }, []);

  const handleInputChange = (e) => {
    dispatch(DashBoardData(e.target.value));
  };

  var labels = [];
  for (var i = 0; i < completeUniqueMonth?.length; i++) {
    if (completeUniqueMonth[i] == '01') {
      var obje = 'January';
    }
    if (completeUniqueMonth[i] == '02') {
      var obje = 'February';
    }
    if (completeUniqueMonth[i] == '03') {
      var obje = 'March';
    }
    if (completeUniqueMonth[i] == '04') {
      var obje = 'April';
    }
    if (completeUniqueMonth[i] == '05') {
      var obje = 'May';
    }
    if (completeUniqueMonth[i] == '06') {
      var obje = 'June';
    }
    if (completeUniqueMonth[i] == '07') {
      var obje = 'July';
    }
    if (completeUniqueMonth[i] == '08') {
      var obje = 'August';
    }
    if (completeUniqueMonth[i] == '09') {
      var obje = 'September';
    }
    if (completeUniqueMonth[i] == '10') {
      var obje = 'October';
    }
    if (completeUniqueMonth[i] == '11') {
      var obje = 'November';
    }
    if (completeUniqueMonth[i] == '12') {
      var obje = 'December';
    }

    labels.push(obje);
  }

  const data = {
    labels,
    datasets: [
      {
        label: 'Due',
        data: lastDateGraphArr,
        backgroundColor: '#4ac5fb',
      },
      {
        label: 'Completed',
        data: completeSec,
        backgroundColor: '#3454cc',
      },
    ],
  };

  var today = new Date();
  var dd = String(today.getDate()).padStart(2, '0');
  var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
  var yyyy = today.getFullYear();

  today = yyyy + '-' + mm + '-' + dd;

  var currentPausedData = timesheet?.filter(function (el) {
    // console.log(
    //   moment(el?.updatedAt).format('YYYY-MM-DD'),
    //   today,
    //   'fdsafdasfdsafdafdsafdasfdsdfsafdasfdsafdsa'
    // );
    return (
      new Date(today).getTime() ==
      new Date(moment(el?.updatedAt).format('YYYY-MM-DD')).getTime()
    );
  });

  const columns = [
    {
      id: 1,
      name: 'Job Ref NO',
      selector: (row) => <b>
        {row?.job_id?.JobRefNo?.toString().length == 1
          ? '000' + row?.job_id?.JobRefNo
          : '' || row?.job_id?.JobRefNo?.toString().length == 2
            ? '00' + row?.job_id?.JobRefNo
            : '' || row?.job_id?.JobRefNo?.toString().length == 3
              ? '0' + row?.job_id?.JobRefNo
              : '' || row?.job_id?.JobRefNo?.toString().length == 4
                ? row?.job_id?.JobRefNo
                : ''}
      </b>,
      reorder: true,
    },
    {
      id: 2,
      name: 'Client Name',
      selector: (row) => <b>{row?.job_id?.clientId?.clientName}</b>,
      reorder: true,
    },
    {
      id: 2,
      name: 'JobType',
      selector: (row) => {
        return (
          <HashLink to={`/jobs/jobsdetail/${row?.job_id?._id}#${row?._id}`}>
            <b
              className='cursor-pointer'
            //</>onClick={() => {
            //      history.push(`/jobs/jobsdetail/${row[0]?._id}`);
            //    localStorage.setItem('jobEdit', row[0]?._id);
            //  }}
            >
              <b>{row?.jobType}</b>
            </b>
          </HashLink>
        );
      },
      reorder: true,
    },
    // {
    //   id: 3,
    //   name: 'Comment',
    //   selector: (row) => <b>{row?.message}</b>,
    //   reorder: true,
    // },
    {
      id: 4,
      name: 'Status',
      selector: (row) => {
        return (
          <>
            <div className='status-yellow m-1'></div>
          </>
        );
      },
      reorder: true,
    },
  ];
  return (
    <div className='page-wrapper'>
      <div className='content container-fluid'>
        <div className='page-header'>
          <div className='row'>
            <div className='col-sm-12'>
              <h3 className='page-title'>Welcome Admin!</h3>
              <ul className='breadcrumb'>
                <li className='breadcrumb-item active'>Dashboard</li>
              </ul>
            </div>
          </div>
        </div>
        <div className='row'>
          <div className='col-md-6 col-sm-6 col-lg-6 col-xl-4'>
            <div className='card dash-widget'>
              <div className='card-body'>
                <span className='dash-widget-icon'>
                  <i className='fa fa-cubes'></i>
                </span>
                <div className='dash-widget-info'>
                  <h3>{pendingsection}</h3>
                  <span> Sections Not started</span>
                </div>
              </div>
            </div>
          </div>
          <div className='col-md-6 col-sm-6 col-lg-6 col-xl-4'>
            <div className='card dash-widget'>
              <div className='card-body'>
                <span className='dash-widget-icon'>
                  <i className='fa fa-envelope'></i>
                </span>
                <div className='dash-widget-info'>
                  <h3>{Due_in_under}</h3>
                  <span>Sections Due Within 2 Week</span>
                </div>
              </div>
            </div>
          </div>
          <div className='col-md-6 col-sm-6 col-lg-6 col-xl-4'>
            <div className='card dash-widget'>
              <div className='card-body'>
                <span className='dash-widget-icon'>
                  <i className='fa fa-user'></i>
                </span>
                <div className='dash-widget-info'>
                  <h3>{overdudate}</h3>
                  <span>Sections Overdue </span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='row'>
          <div className='col-md-12'>
            <div className='row'>
              <div className='col-md-5 text-center'>
                <div className='card'>
                  <div className='card-body'>
                    <h3 className='card-title'>Total Job Sections</h3>
                    <div className=' form-group'>
                      <select
                        onChange={(e) => handleInputChange(e)}
                        name='jobType'
                        className='form-control'
                        id=''
                      >
                        <option value='' className='d-none'>
                          Select Year
                        </option>
                        {completeUniqueYear?.map((item, index) => {
                          return (
                            <>
                              <option key={index} value={item}>
                                {item}
                              </option>
                            </>
                          );
                        })}
                      </select>
                    </div>
                    <div id='bar-charts'>
                      <Bar options={options} data={data} />
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-md-7 text-center'>
                <div className='card'>
                  <div className='card-body'>
                    <h3 className='card-title'>Paused Sections List </h3>
                    <div id='line-charts'>
                      <DataTable
                        columns={columns}
                        data={currentPausedData}
                        defaultSortFieldId={1}
                        sortIcon={<SortIcon />}
                        pagination
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DashBoard;
