import React, { useEffect, useState } from "react";
import GoogleMapReact from "google-map-react";
import './maping.css';
import location from '../src/components/assets/img/location.svg'
import userlocation from '../src/components/assets/img/user.svg'
import socketio from 'socket.io-client';

// const mapStyles = {
//     width: "441px",
//     height: "441px",
//     borderRadius: ".25rem",
// };

const defaultProps = {
    center: {
        lat: 51.4934,
        lng: 0.0098
    },
    zoom: 8
};
// implementation of this function is needed for codesandbox example to work
// you can remove it otherwise
const distanceToMouse = (pt, mp) => {
    if (pt && mp) {
        // return distance between the marker and mouse pointer
        return Math.sqrt(
            (pt.x - mp.x) * (pt.x - mp.x) + (pt.y - mp.y) * (pt.y - mp.y)
        );
    }
};

const MyMarker = ({ text, tooltip, $hover, lat }) => {


    const handleClick = () => {
    };
    return (<>
        {lat ? <div className={$hover ? "circle hover" : "circle"} onClick={handleClick}>
            {text == 5 && <img src={location} className="loc_img" />}
            {text != 5 && <img src={userlocation} />}
            <span className="circleText" >
                {tooltip}
            </span>
        </div> : <></>}
    </>
    );
};

function Maping(props) {
    const { GetSingleJob, start_lat, start_long } = props;
    const [latestData, setLatestData] = useState();
    const [mapData, setMapData] = useState(defaultProps);
    const socket = socketio.connect(process.env.REACT_APP_SOCKET_URL);
    socket.on('connect_error', (err) => {
        console.log(err.message, 'error');
    });
    socket.on('connect', () => {
    });

    useEffect(() => {
        setLatestData(GetSingleJob)
        if (GetSingleJob[0]?.job_id?.latitude) {
            setMapData((prevData) => {
                return {
                    ...prevData,
                    center: {
                        lat: GetSingleJob[0]?.job_id?.latitude,
                        lng: GetSingleJob[0]?.job_id?.longitude
                    },
                    zoom: 10
                }
            })
        }
    }, [GetSingleJob])

    useEffect(() => {
        socket.on('location_list', (payload) => {
            if (Object.keys(payload).length > 0) {
                if (latestData?.length > 0) {
                    latestData?.forEach((item) => {
                        item?.contractorId?.forEach((data) => {
                            if (data?._id == payload?._id) {
                                data.socket_latitude = payload?.socket_latitude;
                                data.socket_longitude = payload?.socket_longitude;
                            }
                        })
                    });
                }
                // setLatestData([]);
                setLatestData(latestData);
            }
        });
    }, [latestData, socket]);

    function displayMarkers() {
        return latestData?.length > 0 && latestData?.map((store, index) => {
            return (
                // store.jobstatus == 3 &&
                store?.contractorId?.map((item, i) => {
                    return (
                        item?.socket_latitude != null &&
                        <MyMarker
                            key={index}
                            lat={item?.socket_latitude}
                            lng={item?.socket_longitude}
                            text={index}
                            tooltip={`${item?.firstName} ${item?.lastName}`}
                        />
                    )
                })
            );
        });
    }
    return (
        <div className="parent_map">
            <GoogleMapReact
                bootstrapURLKeys={{
                    key: "AIzaSyCAMul7MD_uJu1_hW99ZzJCoAtleH9iJeg",
                    language: "en",
                    region: "US"
                }}
                center={mapData.center}
                zoom={mapData.zoom}
                defaultCenter={defaultProps.center}
                defaultZoom={defaultProps.zoom}
                distanceToMouse={distanceToMouse}
            >
                <MyMarker
                    key={5}
                    lat={(start_lat || latestData && latestData[0]?.job_id?.latitude)}
                    lng={(start_long || latestData && latestData[0]?.job_id?.longitude)}
                    text={5}
                    tooltip={"Job Location"}
                />
                {displayMarkers()}
            </GoogleMapReact>


        </div>
    );
}

export default Maping;
//  googlemapapiKey: "AIzaSyCHgfz8mcz1rEGaDS8meAs-Pu1ucGNEwRk"


