import React, { useState, useEffect } from 'react';
import DataTable from 'react-data-table-component';
import SortIcon from '@material-ui/icons/ArrowDownward';
import { Button, Modal } from 'react-bootstrap';
import DeleteIcon from '@material-ui/icons/Delete';
import { useSelector, useDispatch } from 'react-redux';

import {
  CategoryCreate,
  deletecategory,
  category,
} from '../../Redux/Action/SuperAdminAction';
function CATEGORYLIST() {
  const dispatch = useDispatch();

  const categorydata = useSelector((state) => state.adminList.GetCATEGORY);

  const [show, setShow] = useState(false);
  const [showDelete, setDeleteShow] = useState(false);
  const [categoryname, setName] = useState(null);
  const [del, setDel] = useState('');
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handledeleteShow = (ror_id) => {
    setDel(ror_id);
    setDeleteShow(true);
  };

  const handledeleteClose = () => {
    setDeleteShow(false);
  };

  const handleButtonDeleteClick = (id) => {
    dispatch(deletecategory(id));
    handledeleteClose(false);
  };

  const addcategory = (e) => {
    e.preventDefault();
    dispatch(CategoryCreate({ categoryname }));
    setShow(false);
  };

  const columns = [
    {
      id: 1,
      name: 'Name',
      selector: (row) => <b>{row.categoryname}</b>,
    },

    {
      id: 3,
      name: 'ACTIONS',
      cell: (row) => (
        <>
          <span
            className='cell-right'
            style={{ color: 'red' }}
            onClick={() => handledeleteShow(row._id)}
          >
            <DeleteIcon /> Delete
          </span>
        </>
      ),
    },
  ];

  useEffect(() => { dispatch(category()) }, []);

  return (
    <div className='page-wrapper'>
      <div className='content container-fluid'>
        <div className='page-header'>
          <div className='row align-items-center'>
            <div className='col'>
              <h3 className='page-title'>Category List</h3>
            </div>
            <div className='col-auto float-right ml-auto'>
              <Button className='btn-new' onClick={handleShow}>
                Create category
              </Button>
            </div>
          </div>
        </div>
        <div className='row'>
          <div className='col-md-12 d-flex'>
            <div className=' card-table flex-fill'>
              <div className='card-body'>
                <div className='table-responsive'>
                  <DataTable
                    columns={columns}
                    data={categorydata.data}
                    defaultSortFieldId={1}
                    sortIcon={<SortIcon />}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal show={show} onHide={handleClose} animation={false}>
        <Modal.Header closeButton>
          <Modal.Title>Add category</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='item_priview '>
            <label>Category Name</label>
            <input
              className='form-control '
              style={{ marginBottom: '20px' }}
              type='text'
              onChange={(e) => setName(e.target.value)}
              required
            // onBlur={formik.handleBlur}
            // name='firstName'
            // value={formik.values.firstName}
            />
          </div>
        </Modal.Body>

        <div className='issue'>
          <Button
            // disabled={!pdfPreviewUrl}
            className='btn-new'
            onClick={(e) => addcategory(e)}
          >
            Submit
          </Button>
        </div>
      </Modal>

      <Modal show={showDelete} onHide={handledeleteClose} animation={false}>
        <Modal.Header closeButton className='b-none'></Modal.Header>
        <Modal.Body className='p-4 main_modal'>
          <div className='text_del text-center'>
            <h3>Are you sure?</h3>
          </div>
          <div className='d-flex pb-2 width_cus mt-4 justify-content-around'>
            <Button
              variant='secondary'
              className='btn btn-primary '
              onClick={handledeleteClose}
            >
              Cancel
            </Button>
            <Button
              variant='danger'
              onClick={() => handleButtonDeleteClick(del)}
            >
              Delete
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default CATEGORYLIST;
