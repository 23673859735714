import React from 'react';
import { Container, Spinner } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import './loader.css';
function Loader() {
  const { loading } = useSelector((state) => state.loading);
  return (
    <>
      {loading ? (
        <Container className='loader_style' fluid>
          <Spinner animation='border' />
        </Container>
      ) : (
        <></>
      )}
    </>
  );
}
export default Loader;
