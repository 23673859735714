import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { createJob, editViewJob } from "../../Redux/Action/SuperAdminAction";

const PreviewImage = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const maintainState = useSelector((state) => state?.adminList?.maintainState);
    const [imgCanva, setImgCanva] = useState();
    const [imgCanva2, setImgCanva2] = useState();
    const [positionPointer, setPositionPointer] = useState(0);
    const [planImg, setrPlanImg] = useState("0");
    const [markerState, setMarkerState] = useState([]);
    const [markerState2, setMarkerState2] = useState([]);

    if (Object.keys(maintainState).length === 0) {
        history.push("/jobs/create");
    }

    localStorage.setItem("positionPointer", positionPointer);
    localStorage.setItem("planImg", planImg)

    useEffect(() => {
        var canvas = document.getElementById('Canvas');
        var context = canvas.getContext("2d");
        var Markers = []
        function draw(MarkersTest) {
            // Clear Canvas
            context.fillStyle = "#000";
            context.fillRect(0, 0, canvas.width, canvas.height);

            // Draw map
            // Sprite, X location, Y location, Image width, Image height
            // You can leave the image height and width off, if you do it will draw the image at default size
            context.drawImage(mapSprite, 0, 0, 700, 700);
            const dataURL = canvas.toDataURL();
            if (localStorage.getItem("planImg") == "0") {
                setImgCanva(maintainState?.floor_img1)
            } else if (localStorage.getItem("planImg") == "1") {
                setImgCanva2(maintainState?.floor_img2)
            }

            for (var a = 0; a < MarkersTest?.length; a++) {
                // draw blue rectangle
                context.beginPath();
                context.arc(MarkersTest[a]?.XPos + 10, MarkersTest[a]?.YPos + 13, 11, 0, Math.PI * 2, false);
                context.fillStyle = "#63afd8";
                context.fill();

                // draw transparent red circle
                context.globalAlpha = 0.9;
                context.beginPath();
                context.fillStyle = '#fff';
                context.fillText(MarkersTest[a]?.history, MarkersTest[a]?.XPos + 8, MarkersTest[a]?.YPos + 16);
                context.fill();

                context.closePath();
                const dataURL = canvas.toDataURL();
                if (localStorage.getItem("planImg") == "0") {
                    if (maintainState?.floor_img1 == undefined) {
                        if (maintainState?.floor_img2 == undefined) {

                        } else {
                            setImgCanva2(dataURL)
                        }
                    } else {
                        setImgCanva(dataURL)
                    }

                } else if (localStorage.getItem("planImg") == "1") {
                    if (maintainState?.floor_img2 == undefined) {
                        if (maintainState?.floor_img1 == undefined) {

                        } else {
                            setImgCanva(dataURL)
                        }
                    } else {
                        setImgCanva2(dataURL)
                    }
                }


                // localStorage.getItem("planImg") == "0" ? (
                //     maintainState?.floor_img1 == undefined ? (maintainState?.floor_img2 == undefined ? "" : setImgCanva2(dataURL)) :
                //         setImgCanva(dataURL))
                //     :
                //     maintainState?.floor_img2 == undefined ? (maintainState?.floor_img1 == undefined ? "" : setImgCanva(dataURL)) : 
                //         setImgCanva2(dataURL)

            }


        };

        var Marker = function () {
            this.Sprite = new Image();
            this.Sprite.src = require("../assets/img/circle-2.png")
            this.Width = 20;
            this.Height = 20;
            this.XPos = 0;
            this.YPos = 0;
        }

        var mouseClicked = function (mouse) {
            // Get corrent mouse coords
            var rect = canvas.getBoundingClientRect();
            var mouseXPos = (mouse.x - rect.left);
            var mouseYPos = (mouse.y - rect.top);

            // Move the marker when placed to a better location
            var marker = new Marker();
            marker.XPos = mouseXPos - (marker.Width / 2);
            marker.YPos = mouseYPos - marker.Height;

            if (localStorage.getItem("planImg") == "0") {
                setMarkerState2((prev) => {
                    const arrIndexMain = prev?.findIndex(o => o.history == parseInt(localStorage.getItem("positionPointer")));
                    if (arrIndexMain > -1) {
                        let temp = prev;
                        temp?.splice(arrIndexMain, 1);
                        let ajay = [...temp, { ...marker, history: parseInt(localStorage.getItem("positionPointer")) }]
                        const unique = (arr, props = []) => [...new Map(arr.map(entry => [props.map(k => entry[k]).join('|'), entry])).values()];
                        const newArr = unique(ajay, ['XPos', 'YPos']);
                        draw(newArr);
                        return newArr

                    } else {
                        if (parseInt(localStorage.getItem("positionPointer")) != 0) {
                            let ajay = [...prev, { ...marker, history: parseInt(localStorage.getItem("positionPointer")) }]
                            const unique = (arr, props = []) => [...new Map(arr.map(entry => [props.map(k => entry[k]).join('|'), entry])).values()];
                            const newArr = unique(ajay, ['XPos', 'YPos']);
                            draw(newArr);
                            return newArr
                        }
                        return prev
                    }

                });

            } else if (localStorage.getItem("planImg") == "1") {
                setMarkerState((prev) => {
                    const arrIndexMain = prev?.findIndex(o => o.history == parseInt(localStorage.getItem("positionPointer")));
                    if (arrIndexMain > -1) {
                        let temp = prev;
                        temp?.splice(arrIndexMain, 1);
                        let ajay = [...temp, { ...marker, history: parseInt(localStorage.getItem("positionPointer")) }]
                        const unique = (arr, props = []) => [...new Map(arr.map(entry => [props.map(k => entry[k]).join('|'), entry])).values()];
                        const newArr = unique(ajay, ['XPos', 'YPos']);
                        draw(newArr);
                        return newArr

                    } else {
                        if (parseInt(localStorage.getItem("positionPointer")) != 0) {
                            let ajay = [...prev, { ...marker, history: parseInt(localStorage.getItem("positionPointer")) }]
                            const unique = (arr, props = []) => [...new Map(arr.map(entry => [props.map(k => entry[k]).join('|'), entry])).values()];
                            const newArr = unique(ajay, ['XPos', 'YPos']);
                            draw(newArr);
                            return newArr
                        }
                        return prev
                    }

                });
            }


        }

        // var firstLoad = function () {
        //     context.font = "12px Georgia";
        //     context.textAlign = "center";
        // }

        // firstLoad();

        var mapSprite = new Image();

        mapSprite.onload = () => {
            if (planImg == "0") {
                setMarkerState2((prev) => {
                    draw(prev);
                    return prev
                });
            } else {
                setMarkerState((prev) => {
                    draw(prev);
                    return prev
                });
            }

        };

        mapSprite.src = planImg == "0" ? (
            maintainState?.floor_img1 == undefined ? maintainState?.floor_img2 == undefined ? "" : typeof maintainState?.floor_img2 == "string"
                ? maintainState?.floor_img2 : URL.createObjectURL(maintainState?.floor_img2) :
                (typeof maintainState?.floor_img1 == "string" ?
                    maintainState?.floor_img1 : URL.createObjectURL(maintainState?.floor_img1)))
            :
            maintainState?.floor_img2 == undefined ? "" : typeof maintainState?.floor_img2 == "string"
                ? maintainState?.floor_img2 : URL.createObjectURL(maintainState?.floor_img2)
        // Add mouse click event listener to canvas
        canvas.addEventListener("mousedown", mouseClicked);

    }, [planImg, positionPointer]);

    const submitDetail = (e, type) => {
        e.preventDefault();
        if (localStorage.getItem("editPreview") == "editPreview") {
            const data = { ...maintainState }
            if (imgCanva2 != undefined && maintainState?.floor_img2 != undefined) {
                data.floor_img2 = imgCanva2 || maintainState?.floor_img2
            }

            if (imgCanva != undefined && maintainState?.floor_img1 != undefined) {
                data.floor_img1 = imgCanva || maintainState?.floor_img1
            }
            if (typeof data?.floor_img1 == "object" || typeof data?.floor_img2 == "object") {
                let formData = new FormData();
                for (const [key, value] of Object.entries(data)) {

                    if (key == "job_sections") {
                        formData.append(key, JSON.stringify(value));
                    } else {
                        formData.append(key, value);
                    }
                }
                dispatch(editViewJob(formData, history));
            } else {
                dispatch(editViewJob(data, history));
            }

        } else {
            const dataMain = { ...maintainState }
            if (imgCanva != undefined && maintainState?.floor_img1 != undefined) {
                dataMain.floor_img1 = imgCanva || maintainState?.floor_img1
            }

            if (imgCanva2 != undefined && maintainState?.floor_img2 != undefined) {
                dataMain.floor_img2 = imgCanva2 || maintainState?.floor_img2
            }


            if (typeof dataMain?.floor_img1 == "object" || typeof dataMain?.floor_img2 == "object") {
                let formData = new FormData();
                for (const [key, value] of Object.entries(dataMain)) {

                    if (key == "JobSection") {
                        formData.append(key, JSON.stringify(value));
                    } else {
                        formData.append(key, value);
                    }
                }
                dispatch(createJob(formData, history));
            } else {
                dispatch(createJob(dataMain, history));
            }


        }
        localStorage.setItem("editPreview", "")
        history.push("/jobs/list?page=1");
    };

    const handlePlan = (e) => {
        setrPlanImg(e.target.value)
    }
    return (
        <>

            <div className='page-wrapper'>
                <div className='content container-fluid'>
                    <div className='page-header second-page-header'>
                        <div className='row align-items-center'>
                            <div className='col'>
                                <h3 className='page-title second-page-title'>Client Details</h3>
                                <ul className='breadcrumb'>
                                    <li className='breadcrumb-item '>
                                        {' '}
                                        <span>Information on the client and job site</span>{' '}
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="row mt-5">
                            <div className="col-md-9">
                                <div className="map-img-part">
                                    <canvas id="Canvas" width="700" height="700"></canvas>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="right-side-card">
                                    <select onChange={(e) => handlePlan(e)} className="form-control right-side-select">

                                        {(typeof maintainState?.floor_img1 == "object" || maintainState?.floor_img1?.split(":")[0] == "data") && <option value="0">Floor Plan 1</option>}
                                        {(typeof maintainState?.floor_img2 == "object" || maintainState?.floor_img2?.split(":")[0] == "data") && <option value="1">Floor Plan 2</option>}

                                    </select>
                                    <ul className="section-ul">
                                        {
                                            localStorage.getItem("editPreview") == "editPreview" ?
                                                maintainState?.job_sections?.map((item, i) => {
                                                    return (
                                                        <li onClick={() => setPositionPointer(i + 1)} style={{ cursor: "pointer" }}>
                                                            <span>{i + 1}</span>
                                                            Section {i + 1}
                                                        </li>
                                                    )
                                                }) :
                                                maintainState?.JobSection?.map((item, i) => {
                                                    return (
                                                        <li onClick={() => setPositionPointer(i + 1)} style={{ cursor: "pointer" }}>
                                                            <span>{i + 1}</span>
                                                            Section {i + 1}
                                                        </li>
                                                    )
                                                })

                                        }

                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='border-t-c'></div>
                    <div className='fixed-bottom-btn'>
                        <div className='row d-flex align-items-center'>
                            <div className='col-md-12'>
                                <div className='mt-2 mb-2'>
                                    <button onClick={(e) => {
                                        if (localStorage.getItem("editPreview") == "editPreview") {
                                            history.push(`/jobs/edit/${localStorage.getItem("job_id")}`);
                                        } else {
                                            history.push("/jobs/create")
                                        }

                                    }}
                                        className='btn custom_btn  mr-3 btn-md'>
                                        Cancel
                                    </button>
                                    <button onClick={(e) => submitDetail(e)} type='submit' className='btn btn-primary btn-md'>
                                        Save
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default PreviewImage