import React, { useState, useEffect } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import {
  getAllChatReportsForPdf,
  getClinetList,
  getReportsList,
} from '../../Redux/Action/SuperAdminAction';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { PDFExport } from '@progress/kendo-react-pdf';
import { IMAGE_URL } from '../../config';
import SortIcon from '@material-ui/icons/ArrowDownward';
import DataTable from 'react-data-table-component';

function Report() {

  const history = useHistory();
  const dispatch = useDispatch();

  const [show, setShow] = useState(false);
  const [clientArr, setClientArr] = useState([]);
  const [searchInput, setSearchInput] = useState('');

  const handleClose = () => setShow(false);
  const pdfExportComponent = React.useRef(null);

  const uniqueJobTypes = useSelector((state) => state?.adminList?.reportsForPdf?.data?.jobdetail);
  const client = useSelector((state) => state?.adminList?.GetAllReports?.data);
  const reportsData = useSelector((state) => state?.adminList?.GetAllReports?.getsectionlist);

  const handleFileUpload = () => { };


  // const handleReportId = (e, row) => {
  //   setRowData(row);
  //   dispatch(getAllChatReportsForPdf([row?._id]))
  // }

  // const exportPDFWithComponent = () => {
  //   if (pdfExportComponent.current) {
  //     pdfExportComponent.current.save();
  //   }
  // };

  const dataSearch = {

    statusGetJobColor: clientArr?.length > 0 ? clientArr?.filter((item) =>
      ('000' + item?.job_id?.JobRefNo.toString()).includes(searchInput) ||
      ('00' + item?.job_id?.JobRefNo.toString()).includes(searchInput) ||
      ('0' + item?.job_id?.JobRefNo.toString()).includes(searchInput)
    ) : reportsData?.filter((item) =>
      ('000' + item?.job_id?.JobRefNo.toString()).includes(searchInput) ||
      ('00' + item?.job_id?.JobRefNo.toString()).includes(searchInput) ||
      ('0' + item?.job_id?.JobRefNo.toString()).includes(searchInput)
    ),
  };

  const ReportsForPdf = (rowData) => {
    dispatch(getAllChatReportsForPdf([rowData?._id]))
    if (rowData?.job_id?._id != undefined) {
      history.push(`/report_export/${rowData?.job_id?._id}`)
    }
  }

  const key = 'job_id', key_2 = '_id'
  const uniqueJobs = [...new Map(dataSearch?.statusGetJobColor?.map(item => [item[key][key_2], item])).values()];

  // const handleReportsOverview = (row) => {
  //   localStorage.setItem("jobNo_PDF", row?.job_id?.JobRefNo);
  //   localStorage.setItem("chardNo_PDF", row?.chard_number);
  //   localStorage.setItem("jobType_PDF", row?.jobType);
  //   localStorage.setItem("whichPage", "reports");

  //   history.push(`/report_overview/${row?._id}`)
  // }

  const columns = [
    {
      id: 1,
      name: 'JOB NO.',
      selector: (row) => row?.job_id?.JobRefNo.toString().length == 1
        ? '000' + row?.job_id?.JobRefNo
        : '' || row?.job_id?.JobRefNo.toString().length == 2
          ? '00' + row?.job_id?.JobRefNo
          : '' || row?.job_id?.JobRefNo.toString().length == 3
            ? '0' + row?.job_id?.JobRefNo
            : '' || row?.job_id?.JobRefNo.toString().length == 4
              ? row?.job_id?.JobRefNo
              : '',

    },
    {
      id: 2,
      name: 'STATUS',
      selector: (row) => row?.jobstatus == 1 ? (
        "Created"
      ) : row?.jobstatus == 2 ? (

        "Accepted"

      ) : row?.jobstatus == 3 ? (

        "Started"

      ) : row?.jobstatus == 4 ? (
        "Paused"
      ) : row?.jobstatus == 5 ? (
        "Completed"

      ) : (
        ''
      ),
      sortable: true,
    },
    {
      id: 2,
      name: 'NO. SECTIONS',
      selector: (row) => dataSearch?.statusGetJobColor?.filter((item, i) => item?.job_id?._id == row?.job_id?._id).length
    },

    {
      id: 3,
      name: 'CLIENTS',
      selector: (row) => row?.job_id?.clientId?.clientName,
    },
    {
      id: 4,
      name: 'LAST UPDATED',
      cell: (row) => moment(row?.updatedAt).format("Do MMM YY"),
      selector: (row) => row?.updatedAt,
      sortable: true,
    },
    // {
    //   id: 5,
    //   selector: (row) => (<div onClick={() => handleReportsOverview(row)} className='dark-blue-anchor'>View</div>),
    // },
    {
      id: 5,
      selector: (row) => (<>
        <div className='create_report_btn'>
          <a onClick={
            () => ReportsForPdf(row)
          } href="#">Create Report
            {/* ({checkBox?.length}) */}
          </a>
        </div>
        {/* <input checked={checkBox?.includes(row?._id) ? true : false} onChange={(e) => handleReportId(e.target.value, row)} type="checkbox" value={row?._id} id={`html-${row?._id}`} />
        <label for="html"></label> */}
      </>),
    }
  ];

  var clientArrAgin

  const handleDispatchClient = (value) => {
    if ("view_all" == value) {
      setClientArr(reportsData)
    } else {

      clientArrAgin = reportsData?.filter(function (el) {
        return el?.job_id?.clientId?._id == value;
      })
      setClientArr(clientArrAgin)
    }
  }

  useEffect(() => {
    dispatch(getClinetList());
    dispatch(getReportsList());
  }, []);
  return (
    <div className='page-wrapper'>
      {/* <!-- Page Wrapper --> */}

      {/* <!-- Page Content --> */}
      <div class='content container-fluid'>
        {/* <!-- Page Header --> */}

        <div class='page-header'>
          <div class='row align-items-center d-flex'>
            <div class='col-md-6'>
              <h3 class='page-title'>Reports</h3>
            </div>
            {/* <div class='col-md-6'>
              <div class='input-group'>
                <div class='input-group-prepend'>
                  <span class='input-group-text'>
                    <i class='fa fa-search' aria-hidden='true'></i>
                  </span>
                </div>
                <input
                  type='text'
                  class='form-control border-l-none'
                  placeholder='Search'
                />
              </div>
            </div> */}
          </div>
        </div>

        {/* <!-- /Page Header --> */}
        <div class='row'>
          <div class='col-md-12 d-flex'>
            <div class=' card-table flex-fill'>
              <div class='card-body'>
                <div class='row align-items-center d-flex mb-4'>
                  <div className='col-md-3'>
                    <div className='search-input-in'>
                      <input type="text" onChange={(e) => setSearchInput(e.target.value)} className='form-control fw-bold' placeholder='Keyword Search..'></input>
                    </div>
                  </div>
                  <div className='col-md-2'>
                    <select
                      name='category'
                      className='select-c-in'
                      id=''
                      //  onChange={(e) => handleChangeForm(e)}

                      onChange={(e) => handleDispatchClient(e.target.value)}
                    >
                      <option value='view_all'>View All</option>
                      {client?.map((item) => (
                        <>
                          <option value={item?.job_id?.clientId?._id}>
                            {item?.job_id?.clientId?.clientName}
                          </option>
                        </>
                      ))}
                    </select>
                  </div>
                  <div className='col-md-2'>
                    <select className='select-c-in'>
                      <option>Job Created Date </option>
                      <option>Job Created Date </option>
                      <option>Job Created Date </option>
                    </select>
                  </div>
                  <div className='col-md-2'>
                    <select className='select-c-in'>
                      <option>Report Created Date</option>
                      <option>Report Created Date</option>
                      <option>Report Created Date</option>
                    </select>
                  </div>

                </div>

                {/* <div className='row d-flex align-items-center '>
                  <div className='col-md-6'>
                    <div className='row d-flex align-items-center justify-content-between'>
                      <div className='col-md-6'>
                        <div className='form-group'>
                          <label for=''>Client</label>
                          <select
                            name='category'
                            className='form-control fw-bold select_option'
                            id=''

                            onChange={(e) => handleDispatchClient(e.target.value)}
                          >
                            <option value='view_all'>View All</option>
                            {client?.map((item) => (
                              <>
                                <option value={item?.job_id?.clientId?._id}>
                                  {item?.job_id?.clientId?.clientName}
                                </option> 
                              </>
                            ))}
                          </select>
                        </div>
                      </div>
                      <div className='col-md-6'>
                        <div className='form-group'>
                          <label for=''>Job Number</label>
                          <input type="text" onChange={(e) => setSearchInput(e.target.value)} className='form-control fw-bold' placeholder='Search'></input>
                        </div>
                      </div>
                    </div>

                  </div>
                </div> */}
                <div class='table-responsive reports-align-center'>
                  <DataTable
                    columns={columns}
                    data={uniqueJobs}
                    defaultSortFieldId={1}
                    sortIcon={<SortIcon style={{ fill: "white" }} />}
                    pagination={reportsData?.length > 0 ? 1 : ""}
                  />

                  {/* <table class='  datatable table table-stripped mb-0'>
                    <thead class='custom_color'>
                      <tr>
                        <th>JOB NO.</th>
                        <th>ORCHARD NO.</th>
                        <th>TYPE</th>
                        <th>CLIENT</th>
                        <th>LAST UPDATED</th>
                        <th></th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {reportsData &&
                        reportsData?.map((item, i) => {
                          return (
                            <>
                              <tr>
                                <td>{item?.job_id?.JobRefNo}</td>
                                <td>{item?.chard_number}</td>
                                <td>{item?.jobType}</td>
                                <td>{item?.job_id?.JobRefNo}</td>
                                <td>{item?.updatedAt}</td>
                                <td><NavLink to="/report_overview" className='blue-anchor'>View</NavLink></td>
                                <td>

                                  <input onChange={(e) => handleReportId(e.target.value)} type="checkbox" value={item?._id} id="html1" />
                                  <label for="html"></label>

                                </td>
                              </tr>
                            </>
                          );
                        })}
                    </tbody>
                  </table> */}
                  {
                    uniqueJobTypes?.length > 0 && <PDFExport
                      ref={pdfExportComponent}
                      paperSize='auto'
                      margin={40}
                      fileName={`Report for ${new Date().getFullYear()}`}
                      author='KendoReact Team'
                    >
                      <div
                        style={{
                          position: 'absolute',
                          left: '-100000px',
                          top: 0,
                        }}
                      >
                        <div class='table-responsive hidden-table'>
                          <table class=' table table-stripped mb-0'>
                            <thead class='custom_color'>
                              <tr>
                                <th>Section</th>
                                <th>Image</th>
                                <th>Type</th>
                                <th>Product Used</th>

                                <th>Comments</th>

                              </tr>
                            </thead>
                            <tbody>
                              {uniqueJobTypes?.map((item, i) => {
                                return (
                                  <>
                                    <tr class='text-middle-custom'>
                                      <td class='text-center'>{i + 1}</td>
                                      <td class='table-td-img'>
                                        <img
                                          src={`${IMAGE_URL}${item?.chat_id?.Image}`}
                                          alt=''
                                        />
                                      </td>
                                      <td>
                                        {item?.chat_id?.type == 1
                                          ? 'Job Started'
                                          : item?.chat_id?.type == 4
                                            ? 'General'
                                            : item?.chat_id?.type == 3
                                              ? 'Product Used'
                                              : item?.chat_id?.type == 5 &&
                                              'Job Completed'}
                                      </td>
                                      <td>{item?.chat_id?.product_id?.name}</td>
                                      <td class='comment-scroll-hide'>
                                        <div class='comment-part-td slimscroll'>
                                          {item?.Comment}
                                        </div>
                                      </td>
                                      {/* <td style={{cursor:"pointer"}} onClick={() =>
                                    history.push(
                                      `/report_view_edit_section/${item._id}`
                                    )
                                  }>
                                    <div class='blue-text-an'>
                                      Edit
                                    </div>
                                  </td>
                                   */}

                                      {/* <td
                                    style={{ cursor: 'pointer' }}
                                    onClick={() => handleShow(item)}
                                  >
                                    <div class='blue-text-an'>Edit</div>
                                  </td>
                                  <td
                                    class='arrow_end'
                                    style={{ cursor: 'pointer' }}
                                    onClick={() => deleteReport(item?._id)}
                                  >
                                    <i class='la la-trash'></i>
                                  </td> */}
                                    </tr>
                                  </>
                                );
                              })}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </PDFExport>

                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- /Page Content --> */}

      {/* <!-- /Page Wrapper --> */}
      <>
        <Modal show={show} onHide={handleClose} animation={false}>
          <Modal.Header closeButton>
            <Modal.Title>Upload New Report</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className='item_priview'>
              <label>Report Name</label>
              <input type='text' className='form-control' />
              <div className='inp_file mt-3'>
                <label>Preview</label>
                <div className='preview-file'>
                  <i class='fa fa-file-pdf-o' aria-hidden='true'></i>
                </div>
                <input type='file' />
                <div className='fileupload' onChange={() => handleFileUpload}>
                  Upload File
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button className='btn-new' onClick={handleClose}>
              Upload
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    </div>
  );
}

export default Report;
