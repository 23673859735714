import React, { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import SortIcon from '@material-ui/icons/ArrowDownward';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import { Link, useHistory } from 'react-router-dom';
import moment from 'moment';
import { useSelector, useDispatch } from 'react-redux';
import { Button, Modal } from 'react-bootstrap';
import {
  getContractorList,
  deleteContractor,
} from '../../Redux/Action/SuperAdminAction';
function Contractor() {

  const dispatch = useDispatch();
  const history = useHistory();

  const data = useSelector((state) => state?.adminList?.contractorList);
  const [showDelete, setDeleteShow] = useState(false);
  const [del, setDel] = useState('');

  const handleButtonEditClick = (id) => {
    history.push(`/contractors/${id}`);
  };
  const handleButtonDeleteClick = (id) => {
    dispatch(deleteContractor(id)).then((res) => {
      dispatch(getContractorList());
      setDeleteShow(false);
    });
  };

  const handledeleteShow = (ror_id) => {
    setDel(ror_id);
    setDeleteShow(true);
  };

  const handledeleteClose = () => {
    setDeleteShow(false);
  };

  const columns = [
    {
      id: 1,
      name: 'NAME',
      selector: (row) => <b>{row.firstName + ' ' + row.lastName}</b>,
      reorder: true,
    },
    {
      id: 2,
      name: 'ADDRESS',
      selector: (row) => row.streetAddress,
      reorder: true,
    },
    {
      id: 3,
      name: 'DATE OF BIRTH',
      selector: (row) => moment(row?.dob).format('DD/MM/YYYY'),
      reorder: true,
    },
    {
      id: 4,
      name: 'HOURLY RATE',
      selector: (row) => row.Hourly_Rate.toFixed(2),
      reorder: true,
    },
    {
      id: 5,
      name: 'NI NUMBER',
      selector: (row) => row.NI_Number,
      reorder: true,
    },
    {
      id: 6,
      name: 'UTR NUMBER',
      selector: (row) => row.UTR_Number,
      reorder: true,
    },
    {
      id: 7,
      name: 'LAST LOG IN',
      selector: (row) => row?.lastLogin ? moment(row?.lastLogin).format('DD/MM/YYYY h:mm A') : 'N/A',
      reorder: true,
    },
    {
      id: 8,
      name: 'ACTIONS',
      cell: (row) => (
        <>
          <span
            className='edit_delete'
            onClick={() => handleButtonEditClick(row._id)}
          >
            <EditIcon /> Edit
          </span>
          {'     '}
          <span
            className='ml-3 edit_delete'
            onClick={() => handledeleteShow(row._id)}
          >
            <DeleteIcon /> Delete
          </span>
        </>
      ),
    },
  ];

  var column_new_arr = [];

  for (let i = 0; i < columns?.length; i++) {
    if (i == 3 || i == 4 || i == 5 || i == 6) {
    } else {
      column_new_arr.push(columns[i]);
    }
  }

  useEffect(() => {
    dispatch(getContractorList());
  }, []);

  return (
    <div className='page-wrapper'>
      <div className='content container-fluid'>
        <div className='page-header'>
          <div className='row align-items-center'>
            <div className='col'>
              <h3 className='page-title'>Contractors</h3>
            </div>
            {localStorage.getItem('roles') == 'superadmin' ? (
              <div className='col-auto float-right ml-auto'>
                <Link to='/contractors/create' className='btn add-btn'>
                  {' '}
                  Create
                </Link>
              </div>
            ) : (
              ''
            )}
          </div>
        </div>
        <div className='row'>
          <div className='col-md-12 d-flex'>
            <div className=' card-table flex-fill'>
              <div className='card-body'>
                <div className='table-responsive'>
                  <DataTable
                    columns={
                      localStorage.getItem('roles') == 'superadmin'
                        ? columns
                        : column_new_arr
                    }
                    data={data?.data}
                    defaultSortFieldId={1}
                    sortIcon={<SortIcon />}
                    pagination
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal show={showDelete} onHide={handledeleteClose} animation={false}>
        <Modal.Header closeButton className='b-none'></Modal.Header>
        <Modal.Body className='p-4 main_modal'>
          <div className='text_del text-center'>
            <h3>Are you sure?</h3>
          </div>
          <div className='d-flex pb-2 width_cus mt-4 justify-content-around'>
            <Button
              variant='secondary'
              className='btn btn-primary '
              onClick={handledeleteClose}
            >
              Cancel
            </Button>
            <Button
              variant='danger'
              onClick={() => handleButtonDeleteClick(del)}
            >
              Delete
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default Contractor;
