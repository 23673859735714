import React, { useState, useEffect } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { Dropdown } from 'react-bootstrap';
import logo from '../assets/img/logo.png';
import avatar from '../assets/img/svg-img/user-profile.svg';
import NotificationsIcon from '@material-ui/icons/Notifications';
import { useDispatch } from 'react-redux';
import { NotificationsList } from '../../Redux/Action/SuperAdminAction';
import 'react-toastify/dist/ReactToastify.css';
import { HashLink } from 'react-router-hash-link';
import toaster, { Toaster } from 'react-hot-toast';
import { requestForToken, onMessageListener } from './firebaseInit';
import informationIcon from '../assets/img/information.svg';
import closeicon from '../assets/img/close-line.svg';

function Header() {
  const dispatch = useDispatch();

  const [notification, setNotification] = useState({ title: '', body: '', job_id: '', section_id: '' });
  const [notificationOpenOnclick, setNotificationOpenOnclick] = useState(false);
  const [aj, setaj] = useState([{ title: '', body: 'The job with reference number 1374 has been restarted.' }]);
  const [isOpen, setIsOpen] = useState(false);

  // const notify = () => toaster.custom((t) => <ToastDisplay autoClose={8000} toastId={t.id} closeToast={() => toaster.dismiss(t.id)} />);

  // function ToastDisplay({ autoClose, toastId, closeToast }) {
  //   return (
  //     <div className='custom-pop-flame'>
  //       <div className='row d-flex align-items-center'>
  //         <div className='col-md-2'>
  //           <div className='pop-flame-icon-info'>
  //             <img src={informationIcon} />
  //           </div>
  //         </div>
  //         <div className='col-md-8'>
  //           <div className='pop-text-flame'>
  //             {notification?.title}
  //           </div>
  //         </div>
  //         <div className='col-md-2'>
  //           <div className='pop-flame-icon-close' onClick={closeToast}>
  //             <img src={closeicon} />
  //           </div>
  //         </div>
  //       </div>
  //     </div>
  //   );
  // }

  const notify = () => {
    toaster.custom((t) => (
      <ToastDisplay toastId={t.id} />
    ));
  };

  function ToastDisplay({ toastId }) {
    const closeToast = () => {
      toaster.remove(toastId);
    };

    return (
      <div className="custom-pop-flame">
        <div className="row d-flex align-items-center">
          <div className="col-md-2">
            <div className="pop-flame-icon-info">
              <img src={informationIcon} alt="Info Icon" />
            </div>
          </div>
          <div className="col-md-8">
            <div className="pop-text-flame">
              {notification?.title || 'N/A'}
            </div>
          </div>
          <div className="col-md-2">
            <div
              className="pop-flame-icon-close"
              onClick={closeToast}
            >
              <img src={closeicon} alt="Close Icon" />
            </div>
          </div>
        </div>
      </div>
    );
  }


  useEffect(() => {
    requestForToken();
  }, [])

  onMessageListener()
    .then((payload) => {
      console.log(payload, 'payload Notification');
      console.log(payload?.data, 'Notification data');
      setNotification({
        title: payload?.notification?.title,
        body: payload?.notification?.body,
        job_id: payload?.data?.jobid,
        section_id: payload?.data?.sectionid,
      });
    })
    .catch((err) => console.log('failed: ', err));


  const handleLocal = () => {
    localStorage.removeItem('notification');
    setaj([]);
  };

  useEffect(() => {
    if (notification?.title) {
      let notificationData = localStorage.getItem('notification');
      let a = notificationData ? JSON.parse(notificationData) : [];
      if (!Array.isArray(a)) {
        a = [];
      }
      a.push(notification);
      setaj(a);
      localStorage.setItem('notification', JSON.stringify(a));
      notify();
    }
  }, [notification]);


  useEffect(() => {
    document.body.classList.toggle('mini-sidebar', isOpen);
    document.body.classList.toggle('slide-nav', isOpen);
  }, [isOpen]);

  useEffect(() => {
    dispatch(NotificationsList());
  }, []);

  console.log(aj, 'aj');
  console.log(notification, 'notification');

  return (
    <>
      <Toaster />

      <div className='main-wrapper'>
        <div className='header'>
          <div className='header-left'>
            <Link to='/dashboard' className='logo'>
              <img src={logo} height='35' alt='' />
            </Link>
          </div>
          <a onClick={() => setIsOpen(!isOpen)} id='toggle_btn'>
            <span className='bar-icon'>
              <span></span>
              <span></span>
              <span></span>
            </span>
          </a>
          <div className='page-title-box'>
            <h3></h3>
          </div>
          <a
            id='mobile_btn'
            onClick={() => setIsOpen(!isOpen)}
            className='mobile_btn'
            href='#sidebar'
          >
            <i className='fa fa-bars'></i>
          </a>
          <ul className='nav user-menu'>
            <li className='nav-item dropdown has-arrow main-drop notificaton-hide'>
              <div className={aj.length > 0 ? 'green-dot-noti' : ''}></div>
              <Dropdown
                show={
                  localStorage.getItem('notification') != undefined &&
                    notificationOpenOnclick == true
                    ? false
                    : localStorage.getItem('notification') != undefined &&
                      notificationOpenOnclick == false
                      ? true
                      : false
                }
              >
                <Dropdown.Toggle variant='' id='dropdown-basic'>
                  <span
                    onClick={() =>
                      setNotificationOpenOnclick(!notificationOpenOnclick)
                    }
                    className='user-img'
                  >
                    <NotificationsIcon className='notifications-h' />
                  </span>
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item href='javascript:void(0)'>
                    <div className='notification-header'>Notification List</div>
                    {/* {notifications?.map((item, i) => {
                      return (
                        <>
                          {i <= 6 &&
                            item?.message != '' &&
                            item?.message != undefined && (
                              <div className='noti-content-flame'>
                                <div
                                  className='noti-msg-flame'
                                  onClick={() => handleLocal()}
                                >
                                  <HashLink
                                    to={`/jobs/jobsdetail/${item?.job_id}#${item?.section_id}`}
                                  >
                                    {item?.message}
                                  </HashLink>
                                </div>
                              </div>
                            )}
                        </>
                      );
                    })} */}
                    {aj &&
                      aj?.map((item, i) => {
                        return (
                          <>
                            {i <= 6 && (
                              <div className='noti-content-flame'>
                                <div
                                  className='noti-msg-flame'
                                  onClick={() => handleLocal()}
                                >
                                  <HashLink
                                    to={
                                      item?.holiday_req == 'true'
                                        ? `/holiday`
                                        : `/jobs/jobsdetail/${item?.job_id}#${item?.section_id}`
                                    }
                                  >
                                    {item?.body}
                                  </HashLink>
                                </div>
                              </div>
                            )}
                          </>
                        );
                      })}
                    <div
                      className='view-all-notification'
                      onClick={() => handleLocal()}
                    >
                      <NavLink to={'/notification/list'}>View All</NavLink>
                    </div>
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </li>

            <li className='nav-item dropdown has-arrow main-drop'>
              <Dropdown>
                <Dropdown.Toggle variant='' id='dropdown-basic'>
                  <span className='user-img'>
                    <img src={avatar} alt='' />
                    <span className='status online'></span>
                  </span>
                  Admin
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item >
                    <Link to='/change_password' className='text-decoration-none border-0 text-secondary'>Change Password</Link>
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
}

export default Header;
