import React from "react";
import { useHistory } from "react-router-dom";
import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import { addFaq, createClient } from "../../Redux/Action/SuperAdminAction";
function AddFaq() {
  const history = useHistory();
  const dispatch = useDispatch();

  const validate = (values) => {
    const errors = {};
    if (!values.question) {
      errors.question = "Required*";
    }
    if (!values.answer) {
      errors.answer = "Required*";
    }

    return errors;
  };

  const formik = useFormik({
    initialValues: {
      question: "",
      answer: "",
    },
    validate,
    onSubmit: async (values) => {
      try {
        dispatch(addFaq(values));
        history.push("/faqs")
      } catch (error) {
        console.log(error);
      }
    },
  });


  return (
    <div className="page-wrapper">
      <form className="content container-fluid" onSubmit={formik.handleSubmit}>
        <div className="content container-fluid">
          <div className="page-header second-page-header">
            <div className="row align-items-center">
              <div className="col">
                <h3 className="page-title second-page-title">Add Question</h3>
              </div>
            </div>
          </div>
          {/* <form onSubmit={formik.handleSubmit}> */}
          <div className="row">
            <div className="col-md-12">
              <div className="form-group">
                <label className="col-form-label">
                  Question <span className="text-danger">*</span>
                </label>
                <input className="form-control"
                  type="text"
                  name='question'
                  onChange={formik.handleChange}
                  value={formik.values.question}
                />
                {formik.errors.question && (
                  <p className="formik-error">
                    {formik.errors.question}
                  </p>
                )}
              </div>
            </div>

            <div className="col-md-12">
              <div className="form-group">
                <label className="col-form-label">
                  Answer <span className="text-danger">*</span>
                </label>
                <textarea class="form-control" rows="3"
                  onChange={formik.handleChange}
                  name="answer"
                  value={formik.values.answer}
                ></textarea>
                {formik.errors.answer && (
                  <p className="formik-error">
                    {formik.errors.answer}
                  </p>
                )}
              </div>
            </div>
          </div>
          <div className="border-t-c">
            <div className="col-md-12">
              <div className="mt-3">
                <button className="btn custom_btn  mr-3 btn-md"
                  onClick={() => {
                    history.push('/faqs')
                  }}>Cancel</button>
                <button className="btn btn-primary btn-md" htmlType="submit">Add</button>
              </div>
            </div>
          </div>
          {/* </form> */}
        </div>
      </form>
    </div>
  );
}

export default AddFaq;
