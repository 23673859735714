import axios from 'axios';
import { API_URL } from '../../../config';
import {
  USER_LOGIN_FAIL,
  USER_LOGIN_REQUEST,
  USER_LOGIN_SUCCESS,
  START_LOADING,
  STOP_LOADING,
} from '../../Constant/constant';
import { toast } from '../../../components/ToastContainer/Toast';
import API from '../../../Service/Api';
//register action
export const login = (userInfo, history) => async (dispatch) => {
  try {
    const { data } = await API.post(`/admin/signin`, userInfo);
    dispatch({ type: USER_LOGIN_SUCCESS, payload: data, error: data?.message });
    if (data?.data?.accessToken) {
      toast.success(data?.message);
      // localStorage.setItem('adminInfo', JSON.stringify(data));
      localStorage.setItem('accessToken', data?.data?.accessToken);
      localStorage.setItem('roles', data?.data?.roles);
      localStorage.setItem('email', data?.data?.email);
      localStorage.setItem('data', data?.data);
      localStorage.setItem('userId', data?.data?._id);
      // history.push('/dashboard')
      window.location.href = '/dashboard';
    }
  } catch (error) {
    //dispatch({ type: START_LOADING,payload:true});
    // toast.error(error?.message);
    dispatch({
      type: USER_LOGIN_FAIL,
      // payload: error.message && error.message ? error.message : '',
    });
  }
};
