import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import {
  editReportChat,
  getAllChatReports,
  getReportChatById,
  deleteReportData,
} from '../../Redux/Action/SuperAdminAction';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { PDFExport, savePDF } from '@progress/kendo-react-pdf';
import { Button, Modal } from 'react-bootstrap';
import Accordion from 'react-bootstrap/Accordion';
import { IMAGE_URL } from '../../config';

const Report_view_section = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [selectcontractor, setselectcontractor] = useState(false);
  const [comments, setComments] = useState('');
  const [chat_id, setChat_id] = useState('');
  const container = React.useRef(null);
  const pdfExportComponent = React.useRef(null);
  const [showDelete, setDeleteShow] = useState(false);
  const [del, setDel] = useState('');

  const uniqueJobTypes = useSelector((state) => state?.adminList?.GetReportsChat?.data);
  const client_name = useSelector((state) => state?.adminList?.GetReportsChat?.clientnamedata);

  const handleClose = () => setselectcontractor(false);

  const handleUpdateClose = () => {
    dispatch(editReportChat({ chat_id: chat_id, message: comments }));
    setselectcontractor(false);
  };

  const handleShow = (data) => {
    dispatch(getReportChatById(data._id));
    setComments(data.Comment);
    setChat_id(data._id);
    setselectcontractor(true);
    localStorage.setItem('report_view_section', props.match.params.id);
  };

  const handledeleteClose = () => { setDeleteShow(false) };

  const exportPDFWithComponent = () => {
    if (pdfExportComponent.current) {
      pdfExportComponent.current.save();
    }
  };

  const deleteReport = (ror_id) => {
    setDel(ror_id);
    setDeleteShow(true);
  };

  const handleButtonDeleteClick = (id) => {
    dispatch(deleteReportData(id));
    handledeleteClose(false);
  };

  useEffect(() => {
    dispatch(getAllChatReports(props.match.params.id));
  }, [props.match.params.id]);

  return (
    <>
      {/* <!-- Page Wrapper --> */}
      <div class='page-wrapper'>
        {/* <!-- Page Content --> */}
        <div class='content container-fluid'>
          {/* <!-- Page Header --> */}

          <div class='page-header'>
            <div class='row align-items-center d-flex'>
              <div class='col-md-5'>
                <h3 class='page-title'>{client_name}</h3>
              </div>
              <div class='col-md-4'>
                <div class='form-group mb-0'>
                  <select class='form-control' id=''>
                    <option>0001</option>
                    <option>0002</option>
                  </select>
                </div>
              </div>
              <div class='col-md-3 d-flex justify-content-end'>
                <h4>Section 1</h4>
              </div>
            </div>
          </div>
          <div className='row mb-2'>
            <div className='col-md-12'>
              <div
                onClick={() =>
                  history.push(
                    `/report_type_select_section/${localStorage.getItem(
                      'report_type_select_sec_1'
                    )}/${localStorage.getItem('report_type_select_sec_2') == "Electrical/Alarm" ? "Electrical-Alarm" : localStorage.getItem('report_type_select_sec_2')}`
                  )
                }
              >
                <button class='btn add-btn pull-left'>back</button>
              </div>
            </div>
          </div>
          {/* <!-- /Page Header --> */}
          <div class='row'>
            <div class='col-md-12 d-flex'>
              <div class=' card-table flex-fill'>
                <div class='card-body'>
                  <PDFExport
                    ref={pdfExportComponent}
                    paperSize='auto'
                    margin={40}
                    fileName={`Report for ${new Date().getFullYear()}`}
                    author='KendoReact Team'
                  >
                    <div ref={container} class='table-responsive'>
                      <table class=' table table-stripped mb-0'>
                        <thead class='custom_color'>
                          <tr>
                            <th>Section</th>
                            <th>Image</th>
                            <th>Type</th>
                            <th>Product Used</th>

                            <th>Comments</th>

                            <th colspan='2'>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {uniqueJobTypes?.map((item, i) => {
                            return (
                              <>
                                <tr class='text-middle-custom'>
                                  <td class='text-center'>{i + 1}</td>
                                  <td class='table-td-img'>
                                    <img
                                      src={`${IMAGE_URL}${item?.chat_id?.Image}`}
                                      alt=''
                                    />
                                  </td>
                                  <td>
                                    {item?.chat_id?.type == 1
                                      ? 'Job Started'
                                      : item?.chat_id?.type == 4
                                        ? 'General'
                                        : item?.chat_id?.type == 3
                                          ? 'Product Used'
                                          : item?.chat_id?.type == 5 &&
                                          'Job Completed'}
                                  </td>
                                  <td>{item?.chat_id?.product_id?.name}</td>
                                  <td class='comment-scroll-hide'>
                                    <div class='comment-part-td slimscroll'>
                                      {item?.Comment}
                                    </div>
                                  </td>
                                  {/* <td style={{cursor:"pointer"}} onClick={() =>
                                    history.push(
                                      `/report_view_edit_section/${item._id}`
                                    )
                                  }>
                                    <div class='blue-text-an'>
                                      Edit
                                    </div>
                                  </td>
                                   */}

                                  <td
                                    style={{ cursor: 'pointer' }}
                                    onClick={() => handleShow(item)}
                                  >
                                    <div class='blue-text-an'>Edit</div>
                                  </td>
                                  <td
                                    class='arrow_end'
                                    style={{ cursor: 'pointer' }}
                                    onClick={() => deleteReport(item?._id)}
                                  >
                                    <i class='la la-trash'></i>
                                  </td>
                                </tr>
                              </>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                  </PDFExport>
                  <div class='row mt-4'>
                    <div class='col-md-12 d-flex justify-content-end'>
                      <button
                        class='blue-custom-btn'
                        onClick={exportPDFWithComponent}
                      >
                        Save
                      </button>
                      <button
                        class='blue-custom-btn'
                        onClick={exportPDFWithComponent}
                      >
                        Export / View as PDF
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <Modal show={selectcontractor} onHide={handleClose} animation={false}>
            <Modal.Header closeButton>
              <Modal.Title>Update Chat</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className='area_text'>
                <div className='form-group'>
                  <label for='w3review'>Chats</label>

                  <textarea
                    id='w3review'
                    onChange={(e) => setComments(e.target.value)}
                    value={comments}
                    className='form-control'
                    name='w3review'
                    rows='4'
                    cols='40'
                  ></textarea>
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button
                className='btn custom_btn mr-3 btn-md'
                onClick={handleUpdateClose}
              >
                Save
              </Button>
            </Modal.Footer>
          </Modal>
          <Modal show={showDelete} onHide={handledeleteClose} animation={false}>
            <Modal.Header closeButton className='b-none'></Modal.Header>
            <Modal.Body className='p-4 main_modal'>
              <div className='text_del text-center'>
                <h3>Are you sure?</h3>
              </div>
              <div className='d-flex pb-2 width_cus mt-4 justify-content-around'>
                <Button
                  variant='secondary'
                  className='btn btn-primary '
                  onClick={handledeleteClose}
                >
                  Cancel
                </Button>
                <Button
                  variant='danger'
                  onClick={() => handleButtonDeleteClick(del)}
                >
                  Delete
                </Button>
              </div>
            </Modal.Body>
          </Modal>
        </div>
        {/* <!-- /Page Content --> */}
      </div>
      {/* <!-- /Page Wrapper --> */}
    </>
  );
};

export default Report_view_section;
