import React, { useState, useEffect } from 'react';
import DataTable from 'react-data-table-component';
import SortIcon from '@material-ui/icons/ArrowDownward';
import { Button, Modal } from 'react-bootstrap';
import DeleteIcon from '@material-ui/icons/Delete';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import {
  uploadDocument,
  getAllDocument,
  deleteDocument,
} from '../../Redux/Action/SuperAdminAction';
function Document() {
  
  const dispatch = useDispatch();
  const documnetData = useSelector((state) => state.adminList.GetAllDocument);

  const [show, setShow] = useState(false);
  const [file, setfile] = useState(null);
  const [titledata, settitle] = useState(null);
  const [pdfPreviewUrl, setpdfPreviewUrl] = useState('');
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  
  const handleButtonDeleteClick = (id) => {
    dispatch(deleteDocument(id));
  };

  const columns = [
    {
      id: 1,
      name: 'Document Name',
      selector: (row) => <b>{row.document}</b>,
    },
    {
      id: 2,
      name: 'Title',
      selector: (row) => <b>{row?.title}</b>,
    },
    {
      id: 1,
      name: 'Created',
      selector: (row) => <b>{moment(row?.createdAt).format('DD/MM/YYYY')}</b>,
    },
    {
      id: 3,
      name: 'ACTIONS',
      cell: (row) => (
        <>
          <span
            className='cell-right'
            style={{ color: 'red' }}
            onClick={() => handleButtonDeleteClick(row._id)}
          >
            <DeleteIcon /> Delete
          </span>
        </>
      ),
    },
  ];
  const handletitle = (e) => {
    var title = e.target.value;
    settitle(title);
  };

  const handleFileUpload = (e) => {
    e.preventDefault();
    var element = document.getElementById('title');
    let file = e.target.files[0];
    let reader = new FileReader();
    reader.onloadend = () => {
      setfile(file);
      setpdfPreviewUrl(reader.result);
    };
    reader.readAsDataURL(file);
  };

  const handleUploadfile = (e) => {
    e.preventDefault();
    let formData = new FormData();
    formData.append('document', file);
    formData.append('title', titledata);

    dispatch(uploadDocument(formData)).then((res) => {
      setShow(false);
      setpdfPreviewUrl('');
      setfile(null);
    });
  };

  useEffect(() => { dispatch(getAllDocument()); }, []);
  return (
    <div className='page-wrapper'>
      <div className='content container-fluid'>
        <div className='page-header'>
          <div className='row align-items-center'>
            <div className='col'>
              <h3 className='page-title'>Document Library</h3>
            </div>
            <div className='col-auto float-right ml-auto'>
              <Button className='btn-new' onClick={handleShow}>
                Upload New Document
              </Button>
            </div>
          </div>
        </div>
        <div className='row'>
          <div className='col-md-12 d-flex'>
            <div className=' card-table flex-fill'>
              <div className='card-body'>
                <div className='table-responsive'>
                  <DataTable
                    columns={columns}
                    data={documnetData}
                    defaultSortFieldId={1}
                    sortIcon={<SortIcon />}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal show={show} onHide={handleClose} animation={false}>
        <Modal.Header closeButton>
          <Modal.Title>Upload New Document</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='item_priview '>
            <label>Document Title</label>
            <div className='from-group  mb-2'>
              <input
                className='form-control'
                type='text'
                name='title'
                onChange={(e) => handletitle(e)}
              />
            </div>
            <label>Preview</label>
            <div className='inp_file'>
              <div className='preview-file'>
                {pdfPreviewUrl != '' ? (
                  <iframe
                    style={{ cursor: 'pointer' }}
                    onClick={() =>
                      window.open(
                        `http://flamehold.foxbegin.com/${file}`,
                        '_blank'
                      )
                    }
                    src={pdfPreviewUrl}
                  />
                ) : (
                  <i className='fa fa-file-pdf-o' aria-hidden='true' />
                )}
              </div>
              <input
                type='file'
                // accept='application/pdf'
                onChange={(e) => handleFileUpload(e)}
              />
              <div className='fileupload'>Upload File</div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            disabled={!pdfPreviewUrl}
            className='btn-new'
            onClick={(e) => handleUploadfile(e)}
          >
            Upload
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default Document;
