// Firebase Cloud Messaging Configuration File.
// Read more at https://firebase.google.com/docs/cloud-messaging/js/client && https://firebase.google.com/docs/cloud-messaging/js/receive

import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';

const firebaseConfig = {
  apiKey: 'AIzaSyDX3zGeaESpDdokFU23S0U6oqYCMzp6ivo',
  authDomain: 'flamehold-bba96.firebaseapp.com',
  projectId: 'flamehold-bba96',
  storageBucket: 'flamehold-bba96.appspot.com',
  messagingSenderId: '383161256264',
  appId: '1:383161256264:web:01eb83b83178b840f68b7d',
  measurementId: 'G-V0HPGXN61M',
};

initializeApp(firebaseConfig);

const messaging = getMessaging();

// export const requestForToken = async() => {
//   return getToken(messaging, {
//     vapidKey: `BMEhtaqnOSqqq5ITkH4aehiWlY03jKAbDssymt1QW9ELQiH3DqO1UMyssR49bhmnEjbeCTUzk9CshGeP2-a6X-c`,
//   })
//     .then((currentToken) => {
//       if (currentToken) {
//         localStorage.setItem('device_token', currentToken);
//         // Perform any other neccessary action with the token
//       } else {
//         // Show permission request UI
//         console.log(
//           'No registration token available. Request permission to generate one.'
//         );
//       }
//     })
//     .catch((err) => {
//       console.log('An error occurred while retrieving token. ', err);
//     });
// };

export const requestForToken = async () => {
  try {
    const currentToken = await getToken(messaging, {
      vapidKey: 'BMEhtaqnOSqqq5ITkH4aehiWlY03jKAbDssymt1QW9ELQiH3DqO1UMyssR49bhmnEjbeCTUzk9CshGeP2-a6X-c',
    });
    if (currentToken) {
      localStorage.setItem('device_token', currentToken)
      return currentToken;
    } else {
      console.log('No registration token available. Request permission to generate one.');
    }
  } catch (err) {
    console.log('An error occurred while retrieving token. ', err);
  }
};

// Handle incoming messages. Called when:
// - a message is received while the app has focus
// - the user clicks on an app notification created by a service worker `messaging.onBackgroundMessage` handler.
export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      console.log(payload, "onMessageListener Payload")
      resolve(payload);
    });
  });
console.log(messaging, "messagingmessaging")