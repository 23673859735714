import React, { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import SortIcon from '@material-ui/icons/ArrowDownward';
import { Link, useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Button, Modal } from 'react-bootstrap';
import {
  getClinetList,
  deleteClient,
} from '../../Redux/Action/SuperAdminAction';
function Client() {
  const history = useHistory();
  const dispatch = useDispatch();

  const data = useSelector((state) => state.adminList.clientList);

  const [showDelete, setDeleteShow] = useState(false);
  const [del, setDel] = useState('');

  const handleButtonEditClick = (id) => {
    history.push(`/editClient/${id}`);
  };

  const handledeleteShow = (ror_id) => {
    setDel(ror_id);
    setDeleteShow(true);
  };

  const handledeleteClose = () => {
    setDeleteShow(false);
  };

  const handleButtonDeleteClick = (id) => {
    dispatch(deleteClient(id));
    handledeleteClose(false);
  };

  const columns = [
    {
      id: 1,
      name: 'NAME',
      selector: (row) => <b>{row.clientName}</b>,

      reorder: true,
    },
    {
      id: 2,
      name: 'EMAIL',
      selector: (row) => row.email,

      reorder: true,
    },
    {
      id: 3,
      name: 'PHONE NUMBER',
      selector: (row) => row.phone,

      reorder: true,
    },
    {
      id: 4,
      name: 'LOCATION',
      selector: (row) => row.streetAddress,

      reorder: true,
    },
    {
      id: 5,
      name: 'ACTIONS',

      cell: (row) => (
        <>
          <span
            className='cell-right'
            onClick={() => handleButtonEditClick(row._id)}
          >
            {' '}
            Edit
          </span>
          {'     '}
          <span
            className='cell-right'
            style={{ color: 'red' }}
            onClick={() => handledeleteShow(row._id)}
          >
            {' '}
            Delete
          </span>
          {'     '}
        </>
      ),
    },
  ];

  useEffect(() => { dispatch(getClinetList()) }, []);
  return (
    <div className='page-wrapper'>
      <div className='content container-fluid'>
        <div className='page-header'>
          <div className='row align-items-center'>
            <div className='col'>
              <h3 className='page-title'>Clients</h3>
            </div>
            <div className='col-auto float-right ml-auto'>
              <Link to='/clients/create' className='btn add-btn'>
                Create
              </Link>
            </div>
          </div>
        </div>
        <div className='row'>
          <div className='col-md-12 d-flex'>
            <div className=' card-table flex-fill'>
              <div className='card-body'>
                <div className='table-responsive'>
                  <DataTable
                    columns={columns}
                    data={data?.data}
                    defaultSortFieldId={1}
                    sortIcon={<SortIcon />}
                    pagination
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal show={showDelete} onHide={handledeleteClose} animation={false}>
        <Modal.Header closeButton className='b-none'></Modal.Header>
        <Modal.Body className='p-4 main_modal'>
          <div className='text_del text-center'>
            <h3>Are you sure?</h3>
          </div>
          <div className='d-flex pb-2 width_cus mt-4 justify-content-around'>
            <Button
              variant='secondary'
              className='btn btn-primary '
              onClick={handledeleteClose}
            >
              Cancel
            </Button>
            <Button
              variant='danger'
              onClick={() => handleButtonDeleteClick(del)}
            >
              Delete
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default Client;
