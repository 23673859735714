import React from "react";
import {  Redirect, Route } from "react-router-dom";
function ProtectedRoute({ component: Component, ...rest }) {
  const isAuthenticated = localStorage.getItem("accessToken");
  return (
    <Route
      {...rest}
      render={(props) =>
        isAuthenticated ? <Component {...rest} {...props} /> : <></>
      }
    />
  );
}

export default ProtectedRoute;