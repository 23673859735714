import React, { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useFormik } from 'formik';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';
import { editClient, getClientById } from '../../Redux/Action/SuperAdminAction';
function EditClient() {
  const history = useHistory();
  const { id } = useParams();
  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: {
      clientName: '',
      city: '',
      pinCode: '',
      streetAddress: '',
      email: '',
      firstName: '',
      lastName: '',
      phone: '',
    },
    validationSchema: Yup.object({
      firstName: Yup.string()
        .required('FirstName is Required')
        .matches(/^[aA-zZ\s]+$/, 'Only alphabets are allowed for this field '),
      lastName: Yup.string()
        .required('LastName is Required')
        .required('FirstName is Required')
        .matches(/^[aA-zZ\s]+$/, 'Only alphabets are allowed for this field '),
      clientName: Yup.string()
        .required('ClientName is Required')
        .matches(/^[aA-zZ\s]+$/, 'Only alphabets are allowed for this field '),
      city: Yup.string()
        .required('City is Required')
        .matches(/^[aA-zZ\s]+$/, 'Only alphabets are allowed for this field '),
      streetAddress: Yup.string().required('StreetAddress is Required'),
      email: Yup.string().email('Invalid email address').required('Required!'),
      pinCode: Yup.string().required('Post Code is Required'),
      phone: Yup.string()
        .required('Phone is Required')
        .matches(
          /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
          'Phone number is not valid'
        ),
    }),
    onSubmit: (values) => {
      values.id = id;
      dispatch(editClient(values, history));
    },
  });

  useEffect(() => {
    dispatch(getClientById(id)).then((admin) => {
      const fields = [
        'email',
        'firstName',
        'lastName',
        'phone',
        'clientName',
        'streetAddress',
        'city',
        'pinCode',
      ];
      fields.forEach((field) =>
        formik.setFieldValue(field, admin[field], false)
      );
    });
  }, [id]);
  return (
    <div className='page-wrapper'>
      <form className='content container-fluid' onSubmit={formik.handleSubmit}>
        <div className='content container-fluid'>
          <div className='page-header second-page-header'>
            <div className='row align-items-center'>
              <div className='col'>
                <h3 className='page-title second-page-title'>
                  Client Information
                </h3>
                <ul className='breadcrumb'>
                  <li className='breadcrumb-item '>
                    {' '}
                    <span>
                      Use a permanent address where you can recieve mail.
                    </span>{' '}
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className='row'>
            <div className='col-md-6'>
              <div className='form-group'>
                <label className='col-form-label'>
                  Client Name <span className='text-danger'>*</span>
                </label>
                <input
                  className='form-control'
                  name='clientName'
                  type='text'
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.clientName}
                />
                {formik.touched.clientName && formik.errors.clientName ? (
                  <p style={{ color: 'red' }}>{formik.errors.clientName}</p>
                ) : null}
              </div>
            </div>

            <div className='col-md-3'>
              <div className='form-group'>
                <label className='col-form-label'>
                  City <span className='text-danger'>*</span>
                </label>
                <input
                  className='form-control'
                  name='city'
                  type='text'
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.city}
                />
                {formik.touched.city && formik.errors.city ? (
                  <p style={{ color: 'red' }}>{formik.errors.city}</p>
                ) : null}
              </div>
            </div>

            <div className='col-md-3'>
              <div className='form-group'>
                <label className='col-form-label'>
                  Post Code<span className='text-danger'>*</span>
                </label>
                <input
                  className='form-control'
                  name='pinCode'
                  type='text'
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.pinCode}
                />
                {formik.touched.pinCode && formik.errors.pinCode ? (
                  <p style={{ color: 'red' }}>{formik.errors.pinCode}</p>
                ) : null}
              </div>
            </div>

            <div className='col-md-12'>
              <div className='form-group'>
                <label className='col-form-label'>
                  Street Address <span className='text-danger'>*</span>
                </label>
                <input
                  className='form-control'
                  name='streetAddress'
                  type='text'
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.streetAddress}
                />
                {formik.touched.streetAddress && formik.errors.streetAddress ? (
                  <p style={{ color: 'red' }}>{formik.errors.streetAddress}</p>
                ) : null}
              </div>
            </div>
          </div>
          <div className='page-header mt-4 second-page-header'>
            <div className='row align-items-center'>
              <div className='col'>
                <h3 className='page-title second-page-title'>
                  Personal Information
                </h3>
                <ul className='breadcrumb'>
                  <li className='breadcrumb-item '>
                    {' '}
                    <span>
                      Use a permanent address where you can recieve mail.
                    </span>{' '}
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div className='row'>
            <div className='col-md-6'>
              <div className='form-group'>
                <label className='col-form-label'>
                  First Name <span className='text-danger'>*</span>
                </label>
                <input
                  className='form-control'
                  type='text'
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  name='firstName'
                  value={formik.values.firstName}
                />
                {formik.touched.firstName && formik.errors.firstName ? (
                  <p style={{ color: 'red' }}>{formik.errors.firstName}</p>
                ) : null}
              </div>
            </div>

            <div className='col-md-6'>
              <div className='form-group'>
                <label className='col-form-label'>
                  Last Name <span className='text-danger'>*</span>
                </label>
                <input
                  className='form-control'
                  type='text'
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  name='lastName'
                  value={formik.values.lastName}
                />
                {formik.touched.lastName && formik.errors.lastName ? (
                  <p style={{ color: 'red' }}>{formik.errors.lastName}</p>
                ) : null}
              </div>
            </div>

            <div className='col-md-6'>
              <div className='form-group'>
                <label className='col-form-label'>
                  Email <span className='text-danger'>*</span>
                </label>
                <input
                  className='form-control'
                  type='email'
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  name='email'
                  value={formik.values.email}
                />
                {formik.touched.email && formik.errors.email ? (
                  <p style={{ color: 'red' }}>{formik.errors.email}</p>
                ) : null}
              </div>
            </div>

            <div className='col-md-6'>
              <div className='form-group'>
                <label className='col-form-label'>
                  Phone Number <span className='text-danger'>*</span>
                </label>
                <input
                  className='form-control'
                  type='text'
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.phone}
                  name='phone'
                />
                {formik.touched.phone && formik.errors.phone ? (
                  <p style={{ color: 'red' }}>{formik.errors.phone}</p>
                ) : null}
              </div>
            </div>
            <div className='border-t-c'>
              <div className='col-md-12'>
                <div className='mt-3'>
                  <button
                    className='btn custom_btn  mr-3 btn-md'
                    onClick={() => history.push('/clients/list')}
                  >
                    Cancel
                  </button>
                  <button className='btn btn-primary btn-md'>Save</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}

export default EditClient;
