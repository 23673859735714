import {
  GET_ADMIN_LIST,
  GET_CLIENT_LIST,
  GET_CONTRACTOR_LIST,
  GET_SINGLE_ADMIN,
  GET_SINGLE_CONTRACTOR,
  GET_ALL_JOB,
  GET_ALL_DOCUMENTS,
  GET_ALL_HOLIDAY_LIST,
  VIEW_JOB_SUCCESSFULLY,
  GET_ISSUE_REQUEST,
  GET_ISSUE_SUCCESS,
  GET_FORM_SUCCESSFULLY,
  GET_PRODUCT_SUCCESSFULLY,
  GET_CATEGORY_SUCCESSFULLY,
  CREATE_PRODUCTLIST_SUCCESSFULLY,
  GET_SUBFORMS_SUCCESS,
  GET_QUESTIONS_SUCCESS,
  GET_JOBSECTION_SUCCESS,
  GET_REPORTS_SUCCESS,
  GET_REPORTS_BY_ID_SUCCESS,
  GET_REPORTS_JOBTYPE_SUCCESS,
  GET_REPORTS_ALLJOBTYPE_SUCCESS,
  GET_REPORTS_CHAT_SUCCESS,
  GET_TIMESHEET_SUCCESS,
  GET_APPROVE_LIST_REQUEST,
  GET_CONTRACTOR_REF_NO_LIST_REQUEST,
  GET_CONTRACTOR_SECTION_SUCCESS,
  GET_REPORT_CHAT_SUCCESS,
  EDIT_REPORTCHAT_SUCCESSFULLY,
  GET_LATEST_ACTIVITY_SUCCESS,
  GET_SEARCH_SUCCESS,
  GET_ALL_TIMESHEET_SUCCESS,
  GET_ALL_JOBSECTION_SUCCESS,
  GET_NOTIFICATION_LIST_SUCCESS,
  GET_TIMESHEET_SEC_SUCCESS,
  GET_CONTRACTOR_REF_NO_LIST_REQUEST2,
  GET_CONTRACTOR_REF_NO_LIST_SUCCESS,
  ADD_TIMESHEET_SUCCESS,
  GET_DASHBOARD_DATA,
  TIMESHEET_DETAIL_ACCORDING_TO_DATE,
  GET_CALENDER_SUCCESS,
  GET_ADD_TIME_SUCCESS,
  GET_REPORTS_CHAT_REQUEST_PDF,
  GET_REPORTS_CHAT_SUCCESS_PDF,
  GET_ALL_SECTION_SUCCESS_PDF,
  JOB_MAINTAIN_STATE,
  GLOBAL_FILTER
} from '../Constant/constant';
let initialstate = {
  maintainState: {},
  adminList: [],
  clientList: [],
  contractorList: [],
  singleAdmin: {},
  singleContractor: {},
  GetJob: [],
  GetAllDocument: [],
  GetAllHoliday: [],
  GetSingleJob: {},
  GetIssueData: [],
  GetCATEGORY: [],
  GetQrCodes: [],
  contractorHistory: [],
  getCompleteForms: [],
  getProductDetails: [],
  GetSubFormByParams: [],
  GetQuestions: [],
  GetAllJobSection: [],
  GetAllReports: [],
  GetAllReportsById: [],
  GetJobTypes: [],
  GetAllJobTypes: [],
  GetReportsChat: [],
  GetTimeSheet: [],
  GETAPPROVELIST: [],
  Getjobrefeno: [],
  getDropdown: [],
  getChatEdit: [],
  getLatestActivity: [],
  getSearchJob: [],
  getAllTimesheet: [],
  allJobsection: [],
  notify: [],
  allTimesheetData: [],
  ref_number: [],
  add_timesheet_data: [],
  dashboarddata: [],
  timesheetdetails: [],
  calender_details: [],
  reportsForPdf: [],
  allreportsForPdf:[],
  GetJob_MetaData:{},
  Jobsection_MetaData:[],
  globalFilter: {}
};
const superadminReducer = (state = initialstate, action) => {
  switch (action.type) {
    case GET_ADMIN_LIST:
      return {
        ...state,
        adminList: action.payload,
      };
    case GET_CLIENT_LIST:
      return {
        ...state,
        clientList: action.payload,
      };
    case GET_CONTRACTOR_LIST:
      return {
        ...state,
        contractorList: action.payload,
        contractorHistory: action.payload.contractorData,
      };
    case GET_SINGLE_ADMIN:
      return {
        ...state,
        singleAdmin: action.payload,
      };
    case GET_SINGLE_CONTRACTOR:
      return {
        ...state,
        singleContractor: action.payload,
      };
    case GET_ALL_JOB:
      return {
        ...state,
        GetJob: action.payload[0].data,
        GetJob_MetaData: action.payload[0].metadata[0]
      };
    case GET_FORM_SUCCESSFULLY:
      return {
        ...state,
        getCompleteForms: action.payload2,
      };
    case GET_ALL_DOCUMENTS:
      return {
        ...state,
        GetAllDocument: action.payload,
      };
    case GET_ALL_HOLIDAY_LIST:
      return {
        ...state,
        GetAllHoliday: action.payload,
      };
    case VIEW_JOB_SUCCESSFULLY:
      return {
        ...state,
        GetSingleJob: action.payload.data,
        GetQrCodes: action.payload.qrcodes2,
      };
    case GET_ISSUE_SUCCESS:
      return {
        ...state,
        GetIssueData: action.payload,
      };
    case GET_PRODUCT_SUCCESSFULLY:
      return {
        ...state,
        getProductDetails: action.payload,
      };
    case GET_CATEGORY_SUCCESSFULLY:
      return {
        ...state,
        GetCATEGORY: action.payload,
      };
    case CREATE_PRODUCTLIST_SUCCESSFULLY:
      return {
        ...state,
        GETPRODUCT: action.payload,
      };
    case GET_SUBFORMS_SUCCESS:
      return {
        ...state,
        GetSubFormByParams: action.payload,
      };
    case GET_QUESTIONS_SUCCESS:
      return {
        ...state,
        GetQuestions: action.payload,
      };
    case GET_JOBSECTION_SUCCESS:
      return {
        ...state,
        GetAllJobSection: action.payload,
      };
    case GET_ADD_TIME_SUCCESS:
      return {
        ...state,
        GetAddTimeData: action.payload,
      };

    case GET_REPORTS_SUCCESS:
      return {
        ...state,
        GetAllReports: action.payload,
      };
    case GET_REPORTS_BY_ID_SUCCESS:
      return {
        ...state,
        GetAllReportsById: action.payload,
      };
    case GET_REPORTS_JOBTYPE_SUCCESS:
      return {
        ...state,
        GetJobTypes: action.payload,
      };

    case GET_REPORTS_ALLJOBTYPE_SUCCESS:
      return {
        ...state,
        GetAllJobTypes: action.payload,
      };
    case GET_APPROVE_LIST_REQUEST:
      return {
        ...state,
        GETAPPROVELIST: action.payload,
      };
    case GET_REPORTS_CHAT_SUCCESS:
      return {
        ...state,
        GetReportsChat: action.payload,
      };
    case GET_TIMESHEET_SUCCESS:
      return {
        ...state,
        GetTimeSheet: action.payload,
      };
    case GET_CONTRACTOR_REF_NO_LIST_SUCCESS:
      return {
        ...state,
        Getjobrefeno: action.payload,
      };
    case GET_CONTRACTOR_SECTION_SUCCESS:
      return {
        ...state,
        getDropdown: action.payload,
      };
    case GET_REPORT_CHAT_SUCCESS:
      return {
        ...state,
        getChatEdit: action.payload,
      };
    case GET_LATEST_ACTIVITY_SUCCESS:
      return {
        ...state,
        getLatestActivity: action.payload,
      };
    case GET_SEARCH_SUCCESS:
      return {
        ...state,
        getSearchJob: action.payload,
      };
    case GET_ALL_TIMESHEET_SUCCESS:
      return {
        ...state,
        getAllTimesheet: action.payload,
      };
    case GET_ALL_JOBSECTION_SUCCESS:
      return {
        ...state,
        allJobsection: action.payload[0].data,
        Jobsection_MetaData: action.payload[0].metadata[0]
      };

    case GET_NOTIFICATION_LIST_SUCCESS:
      return {
        ...state,
        notify: action.payload,
      };
    case GET_TIMESHEET_SEC_SUCCESS:
      return {
        ...state,
        allTimesheetData: action.payload,
      };
    case GET_CONTRACTOR_REF_NO_LIST_REQUEST2:
      return {
        ...state,
        ref_number: action.payload,
      };
    case ADD_TIMESHEET_SUCCESS:
      return {
        ...state,
        add_timesheet_data: action.payload,
      };
    case GET_DASHBOARD_DATA:
      return {
        ...state,
        dashboarddata: action.payload,
      };
    case TIMESHEET_DETAIL_ACCORDING_TO_DATE:
      return {
        ...state,
        timesheetdetails: action.payload,
      };
    case GET_CALENDER_SUCCESS:
      return {
        ...state,
        calender_details: action.payload,
      };

    case GET_REPORTS_CHAT_SUCCESS_PDF:
      return {
        ...state,
        reportsForPdf: action.payload,
      };

        case GET_ALL_SECTION_SUCCESS_PDF:
      return {
        ...state,
        allreportsForPdf: action.payload,
      };
    case JOB_MAINTAIN_STATE:
      return{
        ...state,
        maintainState: action.payload,
      }
      case GLOBAL_FILTER:
        return{
          ...state,
          globalFilter: action.payload,
        }
      


    default:
      return state;
  }
};
export default superadminReducer;
