import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import DashBoard from "./components/DashBoard/DashBoard";
import Admin from "./components/Admin/Admin";
import AddAdmin from "./components/Admin/AddAdmin";
import Client from "./components/Client/Client";
import AddClient from "./components/Client/AddClient";
import Contractor from "./components/Contractors/Contractor";
import AddContractor from "./components/Contractors/AddContractor";
import Job from "./components/Job/Job";
import AddJob from "./components/Job/AddJob";
import ProductList from "./components/Product/ProductList";
import AddProduct from "./components/Product/AddProduct";
import Calender from "./components/Calender/Calender";
import ViewJob from "./components/Job/ViewJob";
import ProtectedRoute from "./components/AuthGuard/ProtectedRoute";
import SideBar from "./components/SideBar/SideBar";
import Header from "./components/Header/Header";
import Loader from "./components/Loader/loader";
import ChangePassword from "./components/ChangePassword/ChangePassword";
import Documents from "./components/Douments/Document";
import Report from "./components/Report/Report";
import Holiday from "./components/Holiday/Holiday";
import TimeSheet from "./components/TimeSheet/TimeSheet";
import DayDetail from "./components/TimeSheet/DayDetail";
import EditAdmin from "./components/Admin/EditAdmin";
import EditContractor from "./components/Contractors/EditContractor";
import ForgetPassword from "./components/ForgetPassword/ForgetPassword";
import Login from "./components/Login/Login";
import Issue from "./components/Issue/Issue";
import ReportNumber from "./components/Report/ReportNumber";
import Report_type_select_section from "./components/Report/Report_type_select_section";
import Report_completed_forms from "./components/Report/Report_completed_forms";
import Report_type_survey from "./components/Report/Report_type_survey";
import Report_type from "./components/Report/Report_type";
import Report_view_issue from "./components/Report/Report_view_issue";
import Report_view_section from "./components/Report/Report_view_section";
import EditJob from "./components/Job/EditJob";
import CATEGORYLIST from "./components/Category/Category_list";
import EDITPRODUCT from "./components/Product/Editproduct";
import EditClient from "./components/Client/EditClient";
import Report_view_edit_sec from "./components/Report/Report_view_edit_sec";
import JobSection from "./components/JobSection/JobSection";
import Notification from "./components/Notification/Notification";
import Term_condition from "./components/Term_condtion/Term_condition";
import Report_overview from "./components/Report/Report_overview";
import Report_export from "./components/Report/Report_export";
import Pdf_report from "./components/Report/Pdf_report";
import PreviewImage from "./components/Job/PreviewImage";
import Maping from "./Maping";
import Archieve_notify from "./components/Notification/Archieve_notify";
import Risk_pdf from "./components/Job/Risk_pdf";
import AddFaq from "./components/Faqs/AddFaqs";
import Faqs from "./components/Faqs/Faqs";
import EditFaq from "./components/Faqs/EditFaq";
function MainRoute() {
  const isAuthenticated = localStorage.getItem("accessToken");

  return (
    <React.Suspense fallback={null}>
      <Loader />
      <ProtectedRoute component={Header} />
      <ProtectedRoute path="/" component={SideBar} />
      <Switch>
        <ProtectedRoute path="/dashboard" exact={true} component={DashBoard} />
        <ProtectedRoute path="/admins/list" exact={true} component={Admin} />
        <ProtectedRoute
          path="/admins/create"
          exact={true}
          component={AddAdmin}
        />
        <ProtectedRoute
          path="/admins/edit/:id"
          exact={true}
          component={EditAdmin}
        />
        <ProtectedRoute path="/clients/list" exact={true} component={Client} />
        <ProtectedRoute
          path="/clients/create"
          exact={true}
          component={AddClient}
        />
        <ProtectedRoute
          path="/contractors/list"
          exact={true}
          component={Contractor}
        />
        <ProtectedRoute
          path="/contractors/create"
          exact={true}
          component={AddContractor}
        />
        <ProtectedRoute
          path="/contractors/:contractorId"
          exact={true}
          component={EditContractor}
        />
        <ProtectedRoute path="/jobs/list" exact={true} component={Job} />
        <ProtectedRoute path="/jobs/create" exact={true} component={AddJob} />
        <ProtectedRoute
          path="/jobs/edit/:id"
          exact={true}
          component={EditJob}
        />

        <ProtectedRoute path="/jobs/jobsdetail/:id">
          <ViewJob />
        </ProtectedRoute>
        <ProtectedRoute
          path="/products/list"
          exact={true}
          component={ProductList}
        />
        <ProtectedRoute
          path="/add_product"
          exact={true}
          component={AddProduct}
        />
        <ProtectedRoute path="/calendar" exact={true} component={Calender} />
        <ProtectedRoute
          path="/change_password"
          exact={true}
          component={ChangePassword}
        />
        <ProtectedRoute path="/documents" exact={true} component={Documents} />
        <ProtectedRoute path="/reports" exact={true} component={Report} />

        <ProtectedRoute
          path="/report_number/:id"
          exact={true}
          component={ReportNumber}
        />
        <ProtectedRoute
          path="/report_type_select_section/:id/:jobtype"
          exact={true}
          component={Report_type_select_section}
        />

        <ProtectedRoute
          path="/report_completed_forms"
          exact={true}
          component={Report_completed_forms}
        />
        <ProtectedRoute
          path="/report_type_survey"
          exact={true}
          component={Report_type_survey}
        />
        <ProtectedRoute
          path="/report_type/:id"
          exact={true}
          component={Report_type}
        />
        <ProtectedRoute
          path="/report_view_issue"
          exact={true}
          component={Report_view_issue}
        />
        <ProtectedRoute
          path="/report_view_section/:id"
          exact={true}
          component={Report_view_section}
        />

        <ProtectedRoute
          path="/report_view_edit_section/:id"
          exact={true}
          component={Report_view_edit_sec}
        />

        <ProtectedRoute path="/holiday" exact={true} component={Holiday} />
        <ProtectedRoute path="/time_sheet" exact={true} component={TimeSheet} />
        <ProtectedRoute path="/day_detail" exact={true} component={DayDetail} />
        <ProtectedRoute path="/get_issue" exact={true} component={Issue} />
        <ProtectedRoute
          path="/editProduct/:id"
          exact={true}
          component={EDITPRODUCT}
        />
        <ProtectedRoute
          path="/editClient/:id"
          exact={true}
          component={EditClient}
        />

        <ProtectedRoute
          path="/category_list"
          exact={true}
          component={CATEGORYLIST}
        />

        <ProtectedRoute
          path="/jobsection/list"
          exact={true}
          component={JobSection}
        />

        <ProtectedRoute
          path="/notification/list"
          exact={true}
          component={Notification}
        />

        <ProtectedRoute
          path="/notification/archive"
          exact={true}
          component={Archieve_notify}
        />

        <ProtectedRoute
          path="/report_overview/:id"
          exact={true}
          component={Report_overview}
        />

        <ProtectedRoute
          path="/report_export/:id"
          exact={true}
          component={Report_export}
        />

        <ProtectedRoute
          path="/pdf_report"
          exact={true}
          component={Pdf_report}
        />
        <ProtectedRoute
          path="/preview-image"
          exact={true}
          component={PreviewImage}
        />
        <ProtectedRoute path="/map" exact={true} component={Maping} />
        <ProtectedRoute path="/risk" exact={true} component={Risk_pdf} />

        <ProtectedRoute path="/add-faq" exact={true} component={AddFaq} />
        <ProtectedRoute path="/edit-faq/:id" exact={true} component={EditFaq} />
        <ProtectedRoute path="/faqs" exact={true} component={Faqs} />

        {!isAuthenticated ? (
          <>
            <Route exact path="/">
              <Redirect to="/signin" />
            </Route>
            <Route path="/signin" component={Login} />
            <Route path="/reset_password/:id" component={ForgetPassword} />
            <Route path="/term_condition" component={Term_condition} />
          </>
        ) : (
          <Redirect to="/dashboard" />
        )}
      </Switch>
    </React.Suspense>
  );
}

export default MainRoute;
