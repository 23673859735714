import React, { useEffect } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import PrivateRoute from './routes';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { useDispatch } from 'react-redux';
function App() {
  const dispatch = useDispatch();


  return (
    <>
      {/* <ToastContainer autoClose={40000} position='top-center' /> */}
      {/* <Notification /> */}
      <Router>
        <PrivateRoute />
      </Router>
    </>
  );
}

export default App;
