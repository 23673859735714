import React from 'react';
import { NavLink } from 'react-router-dom';

const Report_type_survey = () => {
  return (
    <>
      {/* <!-- Page Wrapper --> */}
      <div class='page-wrapper'>
        {/* <!-- Page Content --> */}
        <div class='content container-fluid'>
          {/* <!-- Page Header --> */}

          <div class='page-header'>
            <div class='row align-items-center d-flex'>
              <div class='col-md-6'>
                <h3 class='page-title'>Onward Homes</h3>
              </div>
              <div class='col-md-6'>
                <div class='form-group mb-0'>
                  <select class='form-control' id=''>
                    <option>0001</option>
                    <option>0002</option>
                  </select>
                </div>
              </div>
            </div>
          </div>

          {/* <!-- /Page Header --> */}
          <div class='row'>
            <div class='col-md-12 d-flex'>
              <div class=' card-table flex-fill'>
                <div class='card-body'>
                  <div class='table-responsive'>
                    <table class=' table table-stripped mb-0'>
                      <thead class='custom_color'>
                        <tr>
                          <th colspan='2'>Issue</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr class='text-middle-custom'>
                          <td>1</td>
                          <td class='arrow_end'>
                            <NavLink to='/report_view_issue'>
                              <i
                                class='fa fa-angle-right'
                                aria-hidden='true'
                              ></i>
                            </NavLink>
                          </td>
                        </tr>
                        <tr class='text-middle-custom'>
                          <td>2</td>
                          <td class='arrow_end'>
                            <NavLink to='/report_view_issue'>
                              <i
                                class='fa fa-angle-right'
                                aria-hidden='true'
                              ></i>
                            </NavLink>
                          </td>
                        </tr>
                        <tr class='text-middle-custom'>
                          <td>3</td>
                          <td class='arrow_end'>
                            <NavLink to='/report_view_issue'>
                              <i
                                class='fa fa-angle-right'
                                aria-hidden='true'
                              ></i>
                            </NavLink>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div class='row mt-4'>
                    <div class='col-md-12 d-flex justify-content-end'>
                      <button class='blue-custom-btn'>
                        Export / View as PDF
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- /Page Content --> */}
      </div>
      {/* <!-- /Page Wrapper --> */}
    </>
  );
};

export default Report_type_survey;
